<template>
  <div class="tab">
    <div class="tab-inner mx-auto p-3 p-lg-4 d-flex flex-column align-items-center justifyContent autoScroll">
      <div class="mb-3 font-weight-bold">
        {{ outroVideoShortDescription }}
      </div>
      <div v-if="outroVideoUrl !== 'N/A'">
        <div style="width:100%;max-height:500px;height:100%;background:black;">
          <video
            ref="outroVideo"
            :src="outroVideoUrl"
            :poster="outroVideoThumbnailUrl"
            autoplay
            playsinline
            controls
            controlsList="nodownload"
            class="mobileVideo video-fluid z-depth-1 mx-auto"
            style="outline:none;margin: 0px;border-radius: 2px;width:100%;height:100%;"
          />
        </div>
      </div>
    </div>
    <div class="bottom-navigation-container">
      <mdb-btn
        size="sm"
        title="NEXT"
        alt="NEXT"
        class="btnSmall"
        color="primary"
        style="width:160px;outline:none;"
        @click="$store.commit('SET_TABDESC', 'FinalMessage')"
      >
        NEXT
      </mdb-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'OutroVideo',
	computed: {
		...mapGetters(['isShineGo', 'job', 'outroVideoUrl', 'outroVideoThumbnailUrl', 'outroVideoShortDescription'])
	},
	beforeMount () {
		var scriptTag = 'var closeElement = document.getElementById("recite-close");'
		scriptTag += '    if (closeElement != null) {'
		scriptTag += '      document.getElementById("recite-close").style.display = "block";'
		scriptTag += '    }'
		scriptTag += '    var newMutationObserver = new MutationObserver(function(mutations) {'
		scriptTag += '    mutations.forEach(function(mutation) {'
		scriptTag += '      var closeModalContent = document.getElementById("recite-modal-content");'
		scriptTag += '      if (closeModalContent !== null) {'
		scriptTag += '        document.getElementById("recite-modal").style.height="220px";'
		scriptTag += '        document.getElementById("recite-modal-content").style.height="100px";'
		scriptTag += '	      var str = "Are you sure you want to close the accessibility toolbar?";'
		scriptTag += '	      closeModalContent.innerHTML = str;'
		scriptTag += '	      newMutationObserver.disconnect();'
		scriptTag += '      }'
		scriptTag += '    });'
		scriptTag += '  });'
		scriptTag += '  newMutationObserver.observe(document.documentElement, { attributes: true, subtree: true });'
		var g = document.createElement('script')
		g.type = 'text/javascript'
		g.async = true
		var s = document.getElementsByTagName('script')[0]
		g.text = scriptTag
		s.parentElement.insertBefore(g, s)
	}
}
</script>

<style lang="scss" scoped>
@media (max-width: 568px) {
  .outlineMobile {
    border: var(--pr-color) solid 2px;
  }
}
@media (min-width: 577px) {
  .pd-1 {
    padding: 0.5rem !important;
  }
}
</style>