// LOCAL
// export const API_URL2 = 'https://localhost:7185'
// export const ENV = "DEV"

// UAT
// export const API_URL2 = 'https://shine-one-way-api.azurewebsites.net' // uat (shine-go-candidate)
// export const ENV = "UAT"

// LIVE
export const API_URL2 = 'https://onewayapi.shineinterview.com' // live (shineinterviewcandidate2)
export const ENV = "LIVE"