<template>
  <div v-if="showBattery && iOS === false">
    <div class="d-flex align-items-center justify-content-between">
      <div
        style="height: 60px;border-radius:15px;position:relative;width:100%;"
        :style="{'border': `${batteryColour} 5px solid` }"
        class="p-1"
      >
        <div
          style="height: 100%;border-radius:7px;"
          :style="{'background': `${batteryColour}99`, 'width': `${batteryLevel * 100}%` }"
          :class="{'flashing': !isCharging}"
        />
        <div style="position:absolute; right:-10px;top:12px;height:25px;width:7px;" :style="{ 'background' : batteryColour }" />
      </div>
    </div>
    <div class="d-flex align-items-center justify-content-between">
      <div
        v-if="!isCharging && batteryLevel < 0.50"
        class="ml-4 mr-2 tabShow"
        style="font-size: 0.7rem;margin-top:15px;margin-left:2px !important;"
      >
        We recommend putting your device on charge before continuing
      </div>
    </div>
  </div>
</template>

<script>
export default {
	name: 'BatteryChecker',
	components: {
	},
	props: {
		iOS: Boolean,
		mobileView: Boolean
	},
	data () {
		return {
			batteryManager: null,
			showBattery: false,
			batteryLevel: 0.19,
			isCharging: false
		}
	},
	computed: {
		batteryColour () {
			return this.batteryLevel < 0.2 ? '#FF0000' : '#008000'
		}
	},
	watch: {
	},
	created () {
		this.getBatteryStatus()
	},
	updated () {
	},
	methods: {
		async getBatteryStatus () {
			try {      
				const BatteryManager = await navigator.getBattery()
				this.batteryLevel = BatteryManager.level
				this.isCharging = BatteryManager.level > 0.49 || BatteryManager.charging
				if (this.mobileView) {
					this.showBattery = true
				} else {
					this.showBattery = false
				}
				BatteryManager.onlevelchange = battery => this.batteryLevel = battery.target.level
				BatteryManager.onchargingchange = battery => this.isCharging = battery.target.charging || battery.target.level > 0.49
				this.$emit('showAlternativeBatteryMessage', false)
			} catch (e) {
				this.$emit('showAlternativeBatteryMessage', true)
				this.showBattery = false
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.flashing {
  animation: lowbattery 2s linear infinite;
}

@keyframes lowbattery {
  0% {
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@media only screen and (max-width: 1280px) {
 .tabShow {
    display: block !important;
  }
}
</style>
