<template>
  <div class="tab">
    <div class="tab-inner mx-auto p-3 p-lg-4 d-flex flex-column align-items-center justifyContent autoScroll">
      <div class="mb-3 font-weight-bold">
        Interview complete!
      </div>
      <div v-if="job.NextSteps.length > 0">
        <div
          v-for="step in job.NextSteps"
          :key="step.NextStepsId"
          class="d-flex align-items-start justify-content-start mb-2 pd-1"
          style="width:100%;"
        >
          <i class="fa fa-check mr-3 p-1" style="height:fit-content; background:var(--pr-color);border-radius:50%;color:var(--lk-color);" />
          <div style="height:100%;width:100%;white-space: pre-wrap;font-size:0.9rem;" class="text-left mb-3">
            {{ step.NextStepsContent }}
          </div>
        </div>
      </div>
      <div v-else>
        <div class="d-flex align-items-start justify-content-start mb-2 pd-1" style="width:100%;">
          <i class="fa fa-check mr-3 p-1" style="height:fit-content; background:var(--pr-color);border-radius:50%;color:var(--lk-color);" />
          <div style="height:100%;width:100%;white-space: pre-wrap;font-size:0.9rem;" class="text-left mb-3">
            You will shortly receive an email confirming all uploads have completed. Upon receiving this, please feel free to close the app or web browser.
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-navigation-container">
      <mdb-btn
        v-if="outroVideoUrl !== 'N/A'"
        size="sm"
        title="NEXT"
        alt="NEXT"
        class="btnSmall"
        color="primary"
        style="width:160px;outline:none;"
        @click="$store.commit('SET_TABDESC', 'OutroVideo')"
      >
        NEXT
      </mdb-btn>
      <mdb-btn
        v-else
        title="NEXT"
        alt="NEXT"
        size="sm"
        class="btnSmall"
        color="primary"
        style="width:160px;outline:none;"
        @click="$store.commit('SET_TABDESC', 'FinalMessage')"
      >
        NEXT
      </mdb-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'OutroContent',
	data () {
		return {}
	},
	computed: {
		...mapGetters(['isShineGo', 'job', 'outroVideoUrl'])
	},
	beforeMount () {
		try {
			var scriptTag = 'var closeElement = document.getElementById("recite-close");'
			scriptTag += '    if (closeElement != null) {'
			scriptTag += '      document.getElementById("recite-close").style.display = "block";'
			scriptTag += '    }'
			scriptTag += '    var newMutationObserver = new MutationObserver(function(mutations) {'
			scriptTag += '    mutations.forEach(function(mutation) {'
			scriptTag += '      var closeModalContent = document.getElementById("recite-modal-content");'
			scriptTag += '      if (closeModalContent !== null) {'
			scriptTag += '        document.getElementById("recite-modal").style.height="220px";'
			scriptTag += '        document.getElementById("recite-modal-content").style.height="100px";'
			scriptTag += '	      var str = "Are you sure you want to close the accessibility toolbar?";'
			scriptTag += '	      closeModalContent.innerHTML = str;'
			scriptTag += '	      newMutationObserver.disconnect();'
			scriptTag += '      }'
			scriptTag += '    });'
			scriptTag += '  });'
			scriptTag += '  newMutationObserver.observe(document.documentElement, { attributes: true, subtree: true });'
			var g = document.createElement('script')
			g.type = 'text/javascript'
			g.async = true
			var s = document.getElementsByTagName('script')[0]
			g.text = scriptTag
			s.parentElement.insertBefore(g, s)
		}
		catch (err) {
			//?
		}
	}
}
</script>

<style lang="scss" scoped>
@media (max-width: 568px) {
  .outlineMobile {
    border: var(--pr-color) solid 2px;
  }
}
@media (min-width: 577px) {
  .pd-1 {
    padding: 0.5rem !important;
  }
}
</style>