<template>
  <div class="tab">
    <div class="tab-inner mx-auto p-3 p-lg-4 d-flex flex-column align-items-center justifyContent autoScroll">
      <div class="text-center mb-4 font-weight-bold" style="font-size:0.9rem;">
        Let's begin by checking we have the right information for you.
      </div>
      <!-- Profile picture -->
      <div v-if="job.AudioOnly === 0">
        <div
          v-if="candidate === undefined"
          style="border-radius:50%;border:2px solid var(--pr-color);position:relative;cursor:pointer;"
          class="p-1"
          @click="avatarCropperModalShowing = true"
        >
          <i
            class="fa fa-camera fa-2x p-5"
            style="border-radius:50%;background:#c1c1c1; border:1px solid #333"
            title="Camera Icon"
            alt="Camera Icon"
          />
          <i class="fa fa-plus" style="position:absolute; right:30%;top:30%;transform:scale(0.75)" />
        </div>
        <div
          v-else
          style="border-radius:50%;border:2px solid var(--pr-color); cursor:pointer;position:relative;width:170px;height:170px;"
          class="profilePicPresent d-flex align-items-center justify-content-center p-1"
        >
          <img
            :src="candidate.ProfilePicture"
            style="object-fit:cover;display:block;border-radius:50%;width:100%;height:100%;"
            title="Profile Picture"
            alt="Profile Picture"
          >
          <div
            class="position-absolute d-flex align-items-center justify-content-center editBlock"
            style="background:#3333334D;height:95%;width:95%;border-radius:50%;"
            @click="avatarCropperModalShowing = true"
          >
            <i
              class="fa fa-edit"
              style="color:#fff"
              title="Edit" 
            />
          </div>
        </div>
      </div>
      <!-- Name -->
      <div class="my-3 font-weight-bold" style="font-size:1.7rem;">
        {{ candidate.CandidateFirstName }} {{ candidate.CandidateSurName }}
      </div>
      <!--phone number-->
      <div style="font-size:1rem;" class="d-flex my-2 ">
        <div v-show="candidate.CandidatePhoneNumber !== '' && candidate.CandidatePhoneNumber !== undefined" style="margin-top:-2px;cursor:pointer;width:100%;;">
          <a :href="'tel:' + candidate.CandidatePhoneNumber" style="color:black !important;text-decoration:none;white-space: nowrap;"><span style="color:black !important;text-decoration:none;">
            <i
              style="font-size:1rem;"
              class="fas fa-phone mr-2 lgfont-resp"
              title="Phone icon"
              alt="Phone icon"
            /> {{ candidate.CandidatePhoneNumber }}</span></a>
        </div>
        <div v-show="candidate.CandidatePhoneNumber === undefined" style="margin-top:-2px;width:100%;">
          <i
            style="font-size:1rem;"
            class="fas fa-phone mr-2 lgfont-resp"
            title="Phone Icon"
            alt="Phone icon"
          /> n/a
        </div>
      </div>
      <!--email-->
      <div style="font-size:1rem;" class="d-flex mb-2 flex-wrap justify-content-center smallF">
        <div
          v-show="candidate.CandidateEmail !== '' && candidate.CandidateEmail !== undefined"
          class="text-wrap"
          style="margin-top:-2px;width:100%;"
        >
          <a :href="'mailto:' + candidate.CandidateEmail" style="color:black !important;text-decoration:none;white-space: nowrap;"><span style="color:black !important;text-decoration:none;">
            <i
              style="font-size:1rem;"
              class="fas fa-envelope mr-2 lgfont-resp"
              title="Email icon"
              alt="Email icon"
            /> {{ candidate.CandidateEmail }}</span></a>
        </div>
        <div v-show="candidate.CandidateEmail === undefined" style="margin-top:-2px;width:100%;">
          <i
            style="font-size:1rem;"
            class="fas fa-envelope mr-2 lgfont-resp"
            title="Email icon"
            alt="Email icon"
          /> n/a
        </div>
      </div>
      <div style="font-size:1rem;" class="d-flex mb-2 flex-wrap justify-content-center smallF">
        <mdb-btn
          size="sm"
          title="EDIT MY DETAILS"
          alt="EDIT MY DETAILS"
          color="primary"
          style="width:160px;outline:none;"
          @click="editMyDetails()"
        >
          EDIT MY DETAILS
        </mdb-btn>
      </div>
    </div>
    <div class="bottom-navigation-container">
      <mdb-btn
        size="sm"
        title="NEXT"
        alt="NEXT"
        class="btnSmall"
        color="primary"
        style="width:160px;outline:none;"
        @click="$store.commit('SET_TABDESC', 'VideoAudioCheck')"
      >
        NEXT
      </mdb-btn>
    </div>

    <div v-if="avatarCropperModalShowing">
      <AvatarCropper
        :loading-crop-image="loadingCropImage"
        :existing-pic="candidate.ProfilePicture"
        :avatar-cropper-modal-showing="avatarCropperModalShowing"
        @setLoadingCropImage="loadingCropImage = $event"
        @handleCrop="handleCrop($event)"
        @closeAvatarCroppingModal="avatarCropperModalShowing = false"
      />
    </div>

    <!-- edit my details modal -->
    <mdb-modal
      v-if="editMyDetailsModal"
      id="editMyDetailsModal"
      @close="closeEditMyDetails()"
    >
      <mdb-modal-header>
        <mdb-modal-title>EDIT MY DETAILS</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <p v-if="message === ''" style="font-size:0.9rem;margin-top:10px;">
          Please update any of your details that are incorrect or missing.
        </p>
        <p v-else style="font-size:0.9rem;margin-top:10px;">
          {{ message }}
        </p>
        <form>
          <div class="md-form" style="width:90%;">
            <div class="md-form form-sm" style="margin-top:35px;">
              <i
                class="fas fa-user prefix sm"
                style="left:0;margin-top:9px;font-size:1rem;color:gray;" 
                title="User icon"
                alt="User icon"
              />
              <mdb-input
                v-model="newF"
                type="text"
                auto-complete="nu-for"
                label="Forename"
                style="margin-left:33px;"
                size="sm"
              />
            </div>
            <div class="md-form form-sm" style="margin-top:35px;">
              <i
                class="fas fa-user prefix sm"
                style="left:0;margin-top:9px;font-size:1rem;color:gray;"
                title="User icon"
                alt="User icon"
              />
              <mdb-input
                v-model="newS"
                type="text"
                auto-complete="nu-sur"
                label="Surname"
                style="margin-left:33px;"
                size="sm"
              />
            </div>
            <div class="md-form form-sm" style="margin-top:35px;">
              <i
                class="fas fa-envelope prefix sm"
                style="left:0;margin-top:9px;font-size:1rem;color:gray;"
                title="Email icon"
                alt="Email icon"
              />
              <mdb-input
                v-model="newE"
                type="email"
                auto-complete="nu-eml"
                label="Email"
                style="margin-left:33px;"
                size="sm"
              />
            </div>
            <div class="md-form form-sm" style="margin-top:35px;">
              <i
                class="fas fa-phone prefix sm"
                style="left:0;margin-top:9px;font-size:1rem;color:gray;"
                title="Phone icon"
                alt="Phone icon"
              />
              <mdb-input
                v-model="newT"
                type="text"
                auto-complete="nu-tel"
                label="Telephone"
                style="margin-left:33px;"
                size="sm"
              />
            </div>
          </div>
        </form>
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn
          v-show="!buttonClick"
          color="primary"
          title="UPDATE"
          alt="UPDATE"
          size="sm"
          style="width:100px;outline:none;"
          @click="updateEditMyDetails()"
        >
          UPDATE
        </mdb-btn>
        <mdb-btn
          v-show="buttonClick"
          color="primary"
          title="UPDATING"
          alt="UPDATING"
          size="sm"
          style="width:100px;outline:none;"
        >
          <mdb-icon icon="fa fa-pulse fa-spinner" />
        </mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import onewayService from "@/api-services/oneway.service"
import AvatarCropper from '@/components/AvatarCropper/avatarCropper.vue'
//import Log from "@/methods/logging.js"

export default {
	name: 'CandidateDetails',
	components: {
		AvatarCropper
	},
	props: {
	},
	data () {
		return {
			avatarCropperModalShowing: false,
			loadingCropImage: false,
			file: '',
			editMyDetailsModal: false,
			newF: '',
			newS: '',
			newE: '',
			newT: '',
			message: '',
			buttonClick: false
		}
	},
	computed: {
		...mapGetters(['candidate', 'invitationCode', 'job']),
	},
	methods: {
		handleCrop (blob) {
			this.loadingCropImage = true
			this.file = blob
			this.uploadProfilePicture()
		},
		async uploadProfilePicture () {
			var formData = new FormData()
			formData.append('formFile', this.file)
			try {
				//await Log.trace("Attempting to change profile picture", 0, this.invitationCode, "InvitationCode")
				const response = await onewayService.profilePictureAnon(this.invitationCode, formData)
				this.candidate.ProfilePicture = response.data
				this.$store.commit('SET_CANDIDATE', this.candidate)
				this.file = ''
				//await Log.trace("Successfully changed profile picture", 0, this.invitationCode, "InvitationCode")
			} catch (err) {
				//await Log.error("An error occurred when trying to save a profile picture - " + err.toString(), 0, this.invitationCode, "InvitationCode")
				console.log(Object.assign({}, err))
				this.file = ''
			}
			this.loadingCropImage = false
		},
		async editMyDetails () {
			this.buttonClick = false
			this.message = ''
			this.newF = this.candidate.CandidateFirstName
			this.newS = this.candidate.CandidateSurName
			this.newE = this.candidate.CandidateEmail
			this.newT = this.candidate.CandidatePhoneNumber
			this.editMyDetailsModal = true
			//await Log.trace("Opened edit details", 0, this.invitationCode, "InvitationCode")
		},
		async closeEditMyDetails () {
			this.buttonClick = false
			this.message = ''
			this.newF = ''
			this.newS = ''
			this.newE = ''
			this.newT = ''
			this.editMyDetailsModal = false
			//await Log.trace("Closed edit details", 0, this.invitationCode, "InvitationCode")
		},
		async updateEditMyDetails () {
			this.buttonClick = true
			const JSONData = {
				InvitationCode: this.invitationCode,
				Fname: this.newF,
				Sname: this.newS,
				Email: this.newE,
				Phone: this.newT
			}
			try {
				//await Log.trace("Started to edit details", 0, this.invitationCode, "InvitationCode")
				const response = await onewayService.editMyDetails(JSONData)
				if (response.data.EmailValid) {
					this.candidate.CandidateFirstName = this.newF
					this.candidate.CandidateSurName = this.newS
					this.candidate.CandidateEmail = this.newE
					this.candidate.CandidatePhoneNumber = this.newT
					if (this.newT === '' || this.newT === null) 
					{
						this.candidate.CandidatePhoneNumber = 'N/A'
					}
					this.$store.commit('SET_CANDIDATE', this.candidate)
					this.closeEditMyDetails()
				} else {
					this.buttonClick = false
					//await Log.trace("Failed to update some of the candidate details -  invalid email", 0, this.invitationCode, "InvitationCode")
					this.message = "Failed to update some of all of your details. Please try again. Note: you may be unable to update your email address if it already exists!"
				}
			} catch (err) {
				//await Log.error("An error occurred when trying to edit details - " + err.toString(), 0, this.invitationCode, "InvitationCode")
				console.log(Object.assign({}, err))
				this.closeEditMyDetails()
			}
		}
	}
}
</script>

<style lang="scss">
@media (min-width: 569px) {
  .margin20 {
    margin-top: 20px;
    padding-bottom: 10px;
  }
}
</style>