<template>
  <div class="tab">
    <div
      v-if="errorDescription === 'RoleDeleted' || errorDescription === 'RoleExpired' || errorDescription === 'GenericError'"
      class="tab-inner mx-auto p-3 p-lg-4 d-flex flex-column align-items-center justifyContent autoScroll"
      style="height:100%;"
    >
      <div class="p-3 outlineMobile  mb-top">
        <div class="text-center mb-3" style="font-size:0.9rem">
          Unfortunately we have been unable to find your video interview.
        </div>
        <div class="text-center mb-3" style="font-size:0.9rem">
          If you wish to get in touch with us about this, our contact details are shown below.
        </div> 
        <div v-if="clientName !== 'N/A' && clientName !== null">
          <div class="text-center mb-3 font-weight-bold" style="font-size:1.2rem">
            {{ clientName }}
          </div> 
          <div style="font-size:0.9rem;text-align: center;" class="d-flex my-2 ">
            <div style="margin-top:-2px;cursor:pointer;width:100%;;">
              <a :href="'tel:' + clientTelephone" style="color:black !important;text-decoration:none;white-space: nowrap;"><span style="color:black !important;text-decoration:none;">
                <i
                  style="font-size:1rem;"
                  class="fas fa-phone mr-2 lgfont-resp"
                  title="Phone icon"
                  alt="Phone icon"
                /> {{ clientTelephone }}</span></a>
            </div>
          </div>
          <!--email-->
          <div style="font-size:0.9rem;text-align: center;" class="d-flex mb-2 flex-wrap justifyContent smallF">
            <div class="text-wrap" style="margin-top:-2px;width:100%;">
              <a :href="'mailto:' + clientEmail" style="color:black !important;text-decoration:none;white-space: nowrap;"><span style="color:black !important;text-decoration:none;">
                <i
                  style="font-size:1rem;"
                  class="fas fa-envelope mr-2 lgfont-resp"
                  title="Email icon"
                  alt="Email icon"
                /> {{ clientEmail }}</span></a>
            </div>
          </div>
        </div>
        <div v-else class="p-3 outlineMobile">
          <div class="text-center mb-3 mobtt" style="font-size:0.9rem;font-weight:bold;margin-top: -10px;margin-bottom:-40px !important;">
            Sorry, we have been unable to find contact details.
            <div class="text-center mb-3 mb-pd" style="font-size:0.9rem;font-weight: normal;">
              <br>For technical enquiries <span style="font-weight:bold;text-transform: uppercase;">only</span> please email 
              <a href="mailto:support@shineinterview.com" style="color:black !important;text-decoration:none;white-space: nowrap;">
                <span style="color:black !important;text-decoration:none;font-weight: bold;">
                  support@shineinterview.com
                </span>
              </a>
            </div> 
          </div> 
        </div>
      </div>
    </div>
    <div
      v-if="errorDescription === 'CandidateDeleted'"
      class="tab-inner mx-auto p-3 p-lg-4 d-flex flex-column align-items-center justifyContent autoScroll"
      style="height:100%;"
    >
      <div class="p-3 outlineMobile">
        <div class="text-center mb-3" style="font-size:0.9rem">
          Unfortunately your invitation to complete a video interview for the position of <span class="font-weight-bold">{{ job.JobTitle }}</span> has been withdrawn.
        </div>
        <div class="text-center mb-3" style="font-size:0.9rem">
          You will have been sent an email confirming this.
        </div> 
        <div class="text-center mb-3" style="font-size:0.9rem">
          If you wish to get in touch with us about this, our contact details are shown below.
        </div> 
        <div v-if="clientName !== 'N/A'">
          <div class="text-center mb-3 font-weight-bold" style="font-size:1.2rem">
            {{ clientName }}
          </div> 
          <div style="font-size:0.9rem;text-align: center;" class="d-flex my-2 ">
            <div style="margin-top:-2px;cursor:pointer;width:100%;;">
              <a :href="'tel:' + clientTelephone" style="color:black !important;text-decoration:none;white-space: nowrap;"><span style="color:black !important;text-decoration:none;">
                <i
                  style="font-size:1rem;"
                  class="fas fa-phone mr-2 lgfont-resp"
                  title="Phone icon"
                  alt="Phone icon"
                /> {{ clientTelephone }}</span></a>
            </div>
          </div>
          <!--email-->
          <div style="font-size:0.9rem;text-align: center;" class="d-flex mb-2 flex-wrap justifyContent smallF">
            <div class="text-wrap" style="margin-top:-2px;width:100%;">
              <a :href="'mailto:' + clientEmail" style="color:black !important;text-decoration:none;white-space: nowrap;"><span style="color:black !important;text-decoration:none;">
                <i
                  style="font-size:1rem;"
                  class="fas fa-envelope mr-2 lgfont-resp"
                  title="Email icon"
                  alt="Email icon"
                /> {{ clientEmail }}</span></a>
            </div>
          </div>
        </div>
        <div v-else class="p-3 outlineMobile mb-top">
          <div class="text-center mb-3" style="font-size:0.9rem;font-weight:bold;margin-top: -10px;margin-bottom:-40px !important;">
            Sorry, we have been unable to find contact details.
          </div> 
        </div>
        <!--support email-->
        <div class="p-3 outlineMobile">
          <div class="text-center mb-3 mb-pd" style="font-size:0.9rem;margin-top: -20px;">
            For technical enquiries <span style="font-weight:bold;text-transform: uppercase;">only</span> please email
            <a href="mailto:support@shineinterview.com" style="color:black !important;text-decoration:none;white-space: nowrap;">
              <span style="color:black !important;text-decoration:none;font-weight: bold;">
                support@shineinterview.com
              </span>
            </a>
          </div> 
        </div>
      </div>
    </div>
    <div
      v-if="errorDescription === 'TimeLimitExpired' || errorDescription === 'InviteCodeExpired'"
      class="tab-inner mx-auto p-3 p-lg-4 d-flex flex-column align-items-center justifyContent autoScroll"
      style="height:100%;"
    >
      <div class="p-3 outlineMobile mb-top">
        <div class="text-center mb-3" style="font-size:0.9rem">
          Unfortunately your invitation to complete a video interview for the position of <span class="font-weight-bold">{{ job.JobTitle }}</span> has expired.
        </div>
        <div class="text-center mb-3" style="font-size:0.9rem">
          If you wish to get in touch with us about this, our contact details are shown below.
        </div> 
        <div v-if="clientName !== 'N/A'">
          <div class="text-center mb-3 font-weight-bold" style="font-size:1.2rem">
            {{ clientName }}
          </div> 
          <div style="font-size:0.9rem;text-align: center;" class="d-flex my-2 ">
            <div style="margin-top:-2px;cursor:pointer;width:100%;;">
              <a :href="'tel:' + clientTelephone" style="color:black !important;text-decoration:none;white-space: nowrap;"><span style="color:black !important;text-decoration:none;">
                <i
                  style="font-size:1rem;"
                  class="fas fa-phone mr-2 lgfont-resp"
                  title="Phone icon"
                  alt="Phone icon"
                /> {{ clientTelephone }}</span></a>
            </div>
          </div>
          <!--email-->
          <div style="font-size:0.9rem;text-align: center;" class="d-flex mb-2 flex-wrap justifyContent smallF">
            <div class="text-wrap" style="margin-top:-2px;width:100%;">
              <a :href="'mailto:' + clientEmail" style="color:black !important;text-decoration:none;white-space: nowrap;"><span style="color:black !important;text-decoration:none;">
                <i
                  style="font-size:1rem;"
                  class="fas fa-envelope mr-2 lgfont-resp"
                  title="Email icon"
                  alt="Email icon"
                /> {{ clientEmail }}</span></a>
            </div>
          </div>
        </div>
        <div v-else class="p-3 outlineMobile">
          <div class="text-center mb-3" style="font-size:0.9rem;font-weight:bold;margin-top: -10px;margin-bottom:-40px !important;">
            Sorry, we have been unable to find contact details.
          </div> 
        </div>
        <!--support email-->
        <div class="p-3 outlineMobile mb-top">
          <div class="text-center mb-3 mb-pd" style="font-size:0.9rem;margin-top: -20px;">
            For technical enquiries <span style="font-weight:bold;text-transform: uppercase;">only</span> please email
            <a href="mailto:support@shineinterview.com" style="color:black !important;text-decoration:none;white-space: nowrap;">
              <span style="color:black !important;text-decoration:none;font-weight: bold;">
                support@shineinterview.com
              </span>
            </a>
          </div> 
        </div>
      </div>
    </div>
    <div
      v-if="errorDescription === 'DeploymentOngoing'"
      class="tab-inner mx-auto p-3 p-lg-4 d-flex flex-column align-items-center justifyContent autoScroll"
      style="height:100%;"
    >
      <div class="p-3 outlineMobile">
        <div class="text-center mb-3" style="font-size:0.9rem">
          Sorry, there are ongoing site updates.
        </div>
        <div class="text-center mb-3" style="font-size:0.9rem">
          Please try again in a few hours.
        </div> 
      </div>
    </div>
    <div
      v-if="errorDescription === 'InviteWasReapplied'"
      class="tab-inner mx-auto p-3 p-lg-4 d-flex flex-column align-items-center justifyContent autoScroll"
      style="height:100%;"
    >
      <div class="p-3 outlineMobile">
        <div class="text-center mb-3" style="font-size:0.9rem">
          You have been reapplied to this role, and this link will no longer work. 
        </div>
        <div class="text-center mb-3" style="font-size:0.9rem">
          Please check your emails for your new interview link.
        </div> 
      </div>
    </div>
    <div
      v-if="errorDescription === 'AlreadyCompleted'"
      class="tab-inner mx-auto p-3 p-lg-4 d-flex flex-column align-items-center justifyContent autoScroll"
      style="height:100%;"
    >
      <div class="p-3 outlineMobile mb-top">
        <div class="text-center mb-3" style="font-size:0.9rem">
          You have already completed the interview.
        </div>
        <div class="text-center mb-3" style="font-size:0.9rem">
          If you wish to get in touch with us about this, our contact details are shown below.
        </div> 
        <div v-if="clientName !== 'N/A'">
          <div class="text-center mb-3 font-weight-bold" style="font-size:1.2rem">
            {{ clientName }}
          </div> 
          <div style="font-size:0.9rem;text-align: center;" class="d-flex my-2 ">
            <div style="margin-top:-2px;cursor:pointer;width:100%;;">
              <a :href="'tel:' + clientTelephone" style="color:black !important;text-decoration:none;white-space: nowrap;"><span style="color:black !important;text-decoration:none;">
                <i
                  style="font-size:1rem;"
                  class="fas fa-phone mr-2 lgfont-resp"
                  title="Phone icon"
                  alt="Phone icon"
                /> {{ clientTelephone }}</span></a>
            </div>
          </div>
          <!--email-->
          <div style="font-size:0.9rem;text-align: center;" class="d-flex mb-2 flex-wrap justifyContent smallF">
            <div class="text-wrap" style="margin-top:-2px;width:100%;">
              <a :href="'mailto:' + clientEmail" style="color:black !important;text-decoration:none;white-space: nowrap;"><span style="color:black !important;text-decoration:none;">
                <i
                  style="font-size:1rem;"
                  class="fas fa-envelope mr-2 lgfont-resp"
                  title="Email icon"
                  alt="Email icon"
                /> {{ clientEmail }}</span></a>
            </div>
          </div>
        </div>
        <div v-else class="p-3 outlineMobile">
          <div class="text-center mb-3" style="font-size:0.9rem;font-weight:bold;margin-top: -10px;margin-bottom:-40px !important;">
            Sorry, we have been unable to find contact details.
          </div> 
        </div>
      </div>
      <!--support email-->
      <div class="p-3 outlineMobile">
        <div class="text-center mb-3 mb-pd" style="font-size:0.9rem;margin-top: -20px;">
          For technical enquiries <span style="font-weight:bold;text-transform: uppercase;">only</span> please email
          <a href="mailto:support@shineinterview.com" style="color:black !important;text-decoration:none;white-space: nowrap;">
            <span style="color:black !important;text-decoration:none;font-weight: bold;">
              support@shineinterview.com
            </span>
          </a>
        </div> 
      </div>
    </div>
    <div
      v-if="errorDescription === 'AndroidInvalid'"
      class="tab-inner mx-auto p-3 p-lg-4 d-flex flex-column align-items-center justifyContent autoScroll"
      style="height:100%;"
    >
      <div class="p-3 outlineMobile">
        <div class="text-center mb-3" style="font-size:0.9rem">
          Your device's operating system is not compatible.
        </div>
        <div class="text-center mb-3" style="font-size:0.9rem">
          Please use an up-to-date version of Android.
        </div>
      </div>
    </div>
    <div 
      v-if="errorDescription === 'LockedInterview'"
      class="tab-inner mx-auto p-3 p-lg-4 d-flex flex-column align-items-center justifyContent autoScroll"
      style="height:100%;"
    >
      <div class="p-3 outlineMobile">
        <div
          class="text-center mb-3"
          style="font-size:0.9rem;font-weight: bold;"
        >
          This interview is locked!
        </div>
        <div
          class="text-center mb-3"
          style="font-size:0.9rem"
        >
          If you wish to get in touch with us about this, our contact details are shown below.
        </div> 
        <div v-if="clientName !== 'N/A'">
          <div class="text-center mb-3 font-weight-bold" style="font-size:1.2rem">
            {{ clientName }}
          </div> 
          <div style="font-size:0.9rem;text-align: center;" class="d-flex my-2 ">
            <div style="margin-top:-2px;cursor:pointer;width:100%;;">
              <a :href="'tel:' + clientTelephone" style="color:black !important;text-decoration:none;white-space: nowrap;"><span style="color:black !important;text-decoration:none;">
                <i
                  style="font-size:1rem;"
                  class="fas fa-phone mr-2 lgfont-resp"
                  title="Phone icon"
                  alt="Phone icon"
                /> {{ clientTelephone }}</span></a>
            </div>
          </div>
          <!--email-->
          <div style="font-size:0.9rem;text-align: center;" class="d-flex mb-2 flex-wrap justifyContent smallF">
            <div class="text-wrap" style="margin-top:-2px;width:100%;">
              <a :href="'mailto:' + clientEmail" style="color:black !important;text-decoration:none;white-space: nowrap;"><span style="color:black !important;text-decoration:none;">
                <i
                  style="font-size:1rem;"
                  class="fas fa-envelope mr-2 lgfont-resp"
                  title="Email icon"
                  alt="Email icon"
                /> {{ clientEmail }}</span></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import onewayService from "@/api-services/oneway.service"
//import Log from "@/methods/logging.js"

export default {
	name: 'ErrorTabs',
	components: {
	},
	props: {
	},
	data () {
		return {
			clientName: 'N/A',
			clientEmail: 'N/A',
			clientTelephone: 'N/A'
		}
	},
	computed: {
		...mapGetters(['candidate', 'errorDescription', 'job'])
	},
	created () {
		const invitationCode = new URLSearchParams(window.location.search).get("inv")
		onewayService.getInTouch(invitationCode).then((response) => {
			this.clientName = response.data.ClientName
			this.clientEmail = response.data.Email
			this.clientTelephone = response.data.Telephone
			if (this.clientName === undefined) {
				this.clientName = 'N/A'
			}
		}).catch((err) => {
			//Log.error("Error when attempting to Get In Touch - " + err.toString(), 0, invitationCode, "InvitationCode")
			this.clientName = 'N/A'
			this.clientEmail = 'N/A'
			this.clientTelephone = 'N/A'
			console.log(err)
		})
	}
}
</script>

<style lang="scss">
@media (max-width: 599px) {
  .mb-pd {
    margin-top: 0px !important;
  }
  .mb-top {
    margin-top:10px !important;
  }
  .mobtt {
    margin-top: 20px !important;
  }
}
</style>
