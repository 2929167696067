import { API_URL2 } from '@/common/config.js'
import Log from "@/methods/logging.js"
import Axios from 'axios'

export default {

	uploadVideo: async function (type, file, data, updateUploadProgress, isUploadRetry = false) {
		
		if (data.questionId > 0 && isUploadRetry) {
			data.GoCandidateQuestionId = data.questionId
		}

		let fileGuid = null
		//Log.debug("Starting to call initialize for questionId: " + data.GoCandidateQuestionId, 0, data.InvitationCode, "InvitationCode")
		try {
			const res = await Axios.post(`${API_URL2}/one_way_api/upload/initialize`, data)
			var uncleanGuid = res.data
			var rem1 = uncleanGuid.replace('"', '')
			var rem2 = rem1.replace('"', '')
			fileGuid = rem2
		} catch (err) {
			console.log(err, Object.assign({}, err))
			updateUploadProgress('Failed', data.GoCandidateQuestionId, err)
			Log.error("An error occurred when trying to upload the video - " + data.GoCandidateQuestionId + " - " + err.toString(), 0, data.InvitationCode, "InvitationCode")
		}

		var size = file.size
		var name = 'name.webm'
		if (file.type === 'video/mp4') {
			name = 'name.mp4'
		}

		console.log('preparing file with size of ' + file.size + ' for question ' + data.GoCandidateQuestionId)

		//var blockSize = size / 9 // chunk less than 9 blocks
		//var blockSize = 2097152 // (1024*1024) * 2; size of one chunk
		
		var blockSize = 1048576 // 1024*1024; size of one chunk
		var blocks = []
		var offset = 0
		var index = 0
		var list = ''

		while (offset < size) {
			var start = offset
			var end = Math.min(offset + blockSize, size)

			blocks.push({
				name, index, start, end
			})

			list += index + ','
			offset = end
			index++
		}

		var putBlocks = []
		var fileCreated = null

		if (typeof (file.name) === 'string') {
			fileCreated = file
		} else {
			fileCreated = new File([file], 'name.webm')
			if (type === 1) {
				fileCreated = new File([file], 'name.mp4')
			}
		}

		blocks.forEach(element => {
			var blobData = fileCreated.slice(element.start, element.end)
			putBlocks.push({
				block: element,
				file: blobData,
				data: data,
				guid: fileGuid
			})
		})

		var tasks = putBlocks.map(getUpload)
		var results = await Promise.all(tasks)
		var fail = false

		results.forEach(element => {
			if (!element) {
				fail = true
			}
		})

		var commitResponse = false

		if (fail) {
			return false
		} else {
			var cData = {
				name: name,
				list: list,
				goCandidateQuestionId: data.GoCandidateQuestionId,
				guid: fileGuid,
				invitationCode: data.InvitationCode
			}
			var commitForm = []
			for (var p in cData) {
				var ek = encodeURIComponent(p)
				var ev = encodeURIComponent(cData[p])
				commitForm.push(ek + '=' + ev)
			}
			commitForm = commitForm.join('&')
			//Log.debug("Starting to call commit for questionId : " + data.GoCandidateQuestionId, 0, data.InvitationCode, "InvitationCode")
			await fetch(`${API_URL2}/one_way_api/upload/commit`, {
				method: 'PUT',
				mode: 'cors',
				headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
				body: commitForm
			}).then(async response => {
				if (response.ok) {
					commitResponse = true
					//added so that video intros / candidate questions return an id
					if (data.videoType === 10 || data.videoType === 7) {
						try {
							const text = await response.text()
							commitResponse = text
						} catch (err) { 
							//?
						}
					}

					console.log('upload complete for question ' + data.GoCandidateQuestionId)

				} else {
					commitResponse = false
				}
			}).catch((err) => {
				updateUploadProgress('Failed', data.GoCandidateQuestionId, err)
				//Log.error("An error occurred when trying to commit the video - " + data.GoCandidateQuestionId + " - " + err.toString(), 0, data.InvitationCode, "InvitationCode")
			})
		}
		return commitResponse

		function getUpload (block) {
			var extension = '.mp4'

			if (file.type === 'video/webm') {
				extension = '.webm'
			}

			if(!isUploadRetry) {
				return uploadBlock(block.block, block.file, block.data, block.guid, extension)
			} else {
				return retryUploadBlock(block.block, block.file, block.data, block.guid, extension)
			}
		}
    
		async function uploadBlock (block, blob, data, guid, extension) {
			var result = false
			var fData = new FormData()
			fData.append('GoCandidateQuestionId', data.GoCandidateQuestionId)
			fData.append('InvitationCode', data.InvitationCode)
			fData.append('guid', guid)
			fData.append('name', block.name)
			fData.append('index', block.index)
			fData.append('file', blob)
			fData.append('Extension', extension)

			console.log('uploading block ' + block.index + ' for question ' + data.GoCandidateQuestionId)

			//Log.debug("Starting to call upload for questionId: " + data.GoCandidateQuestionId + ", block name: " + block.name + " and block index: " + block.index, 0, data.InvitationCode, "InvitationCode")
			
			await fetch(`${API_URL2}/one_way_api/upload/upload`, {
				method: 'POST',
				body: fData
			}).then(async function (response) {
				if (response.ok) {
					//callback to the vue file to show upload progress
					updateUploadProgress(blocks.length, data.GoCandidateQuestionId, null)
					result = true
				}
			}).catch(() => { 
				retryUploadBlock(block, blob, data, guid) 
				//Log.error("An error occurred when trying to Upload the block - " + block.name + " for questionId: " + data.GoCandidateQuestionId + " - " + error.toString(), 0, data.InvitationCode, "InvitationCode")
			})
			return result
		}
    
		async function retryUploadBlock (block, blob, data, guid, extension) {

			var result = false
			var fData = new FormData()
			fData.append('questionId', data.GoCandidateQuestionId)
			fData.append('invitationCode', data.InvitationCode)
			fData.append('guid', guid)
			fData.append('name', block.name)
			fData.append('index', block.index)
			fData.append('file', blob)
			fData.append('Extension', extension)

			console.log('retrying block ' + block.index + ' for question ' + data.GoCandidateQuestionId)

			//Log.debug("Starting to call retry for questionId: " + data.GoCandidateQuestionId+ ", block name: " + block.name + " and block index: " + block.index, 0, data.InvitationCode, "InvitationCode")
		
			await fetch(`${API_URL2}/one_way_api/upload/retry`, {
				method: 'POST',
				body: fData
			}).then(async function (response) {
				if (response.ok) {
					//callback to the vue file to show upload progress
					updateUploadProgress(blocks.length, data.GoCandidateQuestionId, null)
					result = true
				}
			}).catch((err) => {
				updateUploadProgress('Failed', data.GoCandidateQuestionId, err)
				//Log.error("An error occurred when trying to Retry Upload the block - " + block.name + " for questionId: " + data.GoCandidateQuestionId + " - " + error.toString(), 0, data.InvitationCode, "InvitationCode")
			})
			return result
		}
	}
}