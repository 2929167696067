import Axios from "axios";
import { API_URL2 } from "@/common/config";

export default class loggingService {

	constructor() {
		Axios.defaults.headers.common["Access-Control-Allow-Origin"] = API_URL2;
	}
  
	static async log (loggingData) {
		return Axios.post(API_URL2 + '/one_way_api/logging/log', loggingData)
	}
}