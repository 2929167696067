<template>
  <div class="d-flex flex-column justify-content-center align-self-center my-auto p-1 p-md-2 mobH" style="background:#757474; color:#fff;height:calc(40% - 20px);">
    <div
      style="width:100%;min-height:80%;height:fit-content;" 
      class="align-items-center justify-content-center my-auto"
    >
      <div
        style="max-height:95%; overflow-y:auto;font-size:1rem;font-weight:bold;white-space: pre-wrap;"
        class="smfont-resp m-auto text-center p-2 qSize hide-scrollbar"
      >
        <div v-if="question.ScenarioTitle === 'N/A'">
          <span v-if="question.IsVideo && !blobRecording && !recordStart && !question.IsPractiseQuestion && !isScenario && viewingQuestionVideo">
            <div>Please watch the video above.</div>
            <div style="font-size: small; letter-spacing: 1px" class="mt-2">
              <span v-if="job.ReadingTime > 0">Once watched you will have {{ convertTime(job.ReadingTime) }} to prepare before recording automatically begins.</span>
              <span v-else>Note that once watched you will be able to choose when you start recording.</span>
            </div>
          </span>
          <div v-if="!viewingQuestionVideo">
            {{ question.Description }}
          </div>
        </div>

        <div v-else>
          <span> {{ question.Description }} </span>
        </div>
      </div>      
    </div>


    <div
      class="align-self-start d-flex align-items-center justify-content-between retryDiv" 
      style="background:#757474; color:#fff;width:100%;"
    >
      <div style="cursor:help;">
        <i
          id="tooltip-target-1"
          class="fa-clock fa mx-2"
          :title="'You have ' + questionTime + ' (mm:ss) to answer this question.'"
          :alt="'You have ' + questionTime + ' (mm:ss) to answer this question.'"
        /> {{ questionTime }}
      </div>
      <div 
        v-if="!question.IsPractiseQuestion && question.ScenarioTitle === 'N/A' && job.AllowRetries && job.RetryCount != 0 && !viewingQuestionVideo" 
        style="cursor:help;margin-right:10px;"
      >
        <i
          v-if="retriesLeft === 1"
          id="tooltip-target-2"
          class="fa-sync fa mx-2"
          :title="'You currently have 1 retry remaining.'"
          :alt="'You currently have 1 retry remaining.'"
        />
        <i
          v-else-if="job.RetryCount >= 999"
          id="tooltip-target-2"
          class="fa-sync fa mx-2"
          :title="'You currently have unlimited retries.'"
          :alt="'You currently have unlimited retries.'"
        />
        <i
          v-else
          id="tooltip-target-2"
          class="fa-sync fa mx-2"
          :title="'You currently have ' + retriesLeft + ' retries remaining.'"
          :alt="'You currently have ' + retriesLeft + ' retries remaining.'"
        /> {{ job.RetryCount >= 999 ? 'unlimited' : retriesLeft }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
	name: 'Question',
	props: {
		question: {
			type: Object,
			default: () => {}
		},
		recordStart: {
			type: Boolean,
			default: false
		},
		retriesLeft: {
			type: Number,
			default: 0
		},
		viewingQuestionVideo: {
			type: Boolean,
			default: false
		},
		attempt: {
			type: Number,
			default: 1
		},
		allowedPreparationTime: {
			type: Number,
			default: 300
		},
		blobRecording: {
			type: Blob,
			default: null
		}
	},
	methods: {
		convertTime (time) {
			var mins = Math.floor(time / 60);
			var secs = time % 60;
			var readableSecs = `${secs} ${secs > 1 ? 'seconds' : 'second'}`
			if (mins > 0) {
				mins = `${mins} ${mins > 1 ? 'minutes' : 'minute'}`
				if (secs > 0) {
					mins += ' and'
				} else { readableSecs = '' }
			} else { mins = '' }
			return `${mins} ${readableSecs}`;
		}
	},
	computed: {
		...mapGetters(["job"]),
		questionTime () {
			var mins = Math.floor(this.question.TimeLimit / 60)
			var secs = (this.question.TimeLimit % 60)
			secs = secs < 10 ? '0' + secs : secs
			return `${mins}:${secs}`
		},
		isScenario () {
			return this.question.ScenarioTitle !== 'N/A'
		},
	}
}
</script>