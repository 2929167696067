<template>
  <div class="tab">
    <div v-if="firstAttempt" class="tab-inner mx-auto p-3 p-lg-4 d-flex flex-column align-items-center justifyContent autoScroll">
      <div v-if="job.AudioOnly === 0" class="p-3 outlineMobile">
        <div class="text-center mb-4 font-weight-bold" style="font-size:1.2rem;">
          Hi {{ candidate.CandidateFirstName }}
        </div>
        <div class="text-center mb-3" style="font-size:0.9rem;">
          Welcome to your video interview for <span class="font-weight-bold">{{ job.ClientName }}</span> for the position of <span class="font-weight-bold">{{ job.JobTitle }}</span>.
        </div>
        <div class="text-center mb-3" style="font-size:0.9rem;">
          First we will check your camera and microphone are working!
        </div>
        <div class="text-center mb-3" style="font-size:0.9rem;">
          If prompted you must give permission to use your camera and microphone otherwise we cannot conduct the interview.
        </div>
        <div class="text-center mb-3" style="font-size:0.9rem;">
          To get started, please click the 'START' button below.
        </div>

        <!--privacy policy-->
        <div
          v-if="mobileView"
          class="text-center mb-3"
          style="font-size:0.9rem;"
        >
          By proceeding you confirm you have read and accepted the terms of the <a
            href="https://www.shineinterview.com/privacy-policy/"
            target="_blank"
            style="color:#212529 !important;"
          ><span class="font-weight-bold">privacy policy</span></a>.
        </div>
        <div
          v-else
          class="text-center mb-3"
          style="font-size:0.9rem;"
        >
          By proceeding you confirm you have read and accepted the terms of the <a
            href="https://www.shineinterview.com/privacy-policy/"
            target="_blank"
            style="color:#212529 !important;"
          ><span class="font-weight-bold">privacy policy</span></a>.
        </div>
      </div>
      <div v-else class="p-3 outlineMobile">
        <div class="text-center mb-4 font-weight-bold" style="font-size:1.2rem;">
          Hi {{ candidate.CandidateFirstName }}
        </div>
        <div class="text-center mb-3" style="font-size:0.9rem;">
          Welcome to your audio interview for <span class="font-weight-bold">{{ job.ClientName }}</span> for the position of <span class="font-weight-bold">{{ job.JobTitle }}</span>.
        </div>
        <div class="text-center mb-3" style="font-size:0.9rem;">
          First we will check your microphone is working!
        </div>
        <div class="text-center mb-3" style="font-size:0.9rem;">
          If prompted you must give permission to use your microphone otherwise we cannot conduct the interview.
        </div>
        <div class="text-center mb-3" style="font-size:0.9rem;">
          To get started, please click the 'START' button below.
        </div>

        <!--privacy policy-->
        <div
          v-if="mobileView"
          class="text-center mb-3"
          style="font-size:0.9rem;"
        >
          By proceeding you confirm you have read and accepted the terms of the <a
            href="https://www.shineinterview.com/privacy-policy/"
            target="_blank"
            style="color:#212529 !important;"
          ><span class="font-weight-bold">privacy policy</span></a>.
        </div>
        <div
          v-else
          class="text-center mb-3"
          style="font-size:0.9rem"
        >
          By proceeding you confirm you have read and accepted the terms of the <a
            href="https://www.shineinterview.com/privacy-policy/"
            target="_blank"
            style="color:#212529 !important;"
          ><span class="font-weight-bold">privacy policy</span></a>.
        </div>
      </div>
    </div>
    <div v-else class="tab-inner mx-auto p-3 p-lg-4 d-flex flex-column align-items-center justifyContent autoScroll">
      <div class="p-3 outlineMobile">
        <div class="text-center mb-4 font-weight-bold" style="font-size:1.2rem;">
          HI {{ candidate.CandidateFirstName }}
        </div>
        <div class="text-center mb-3" style="font-size:0.9rem;">
          We have found a partially completed Shine Interview for you.
        </div>
        <div class="text-center mb-3" style="font-size:0.9rem;">
          {{ initialUnansweredCount }} of the {{ questionCount }} questions have been successfully completed .
        </div>
        <div class="text-center mb-3" style="font-size:0.9rem;">
          Please press 'RESUME' below and you will be able to completed the {{ (questionCount) - initialUnansweredCount }} remaining unanswered question{{ initialUnansweredCount === 1 ? '' : 's' }}.
        </div>
      </div>
    
      <!--privacy policy-->
      <div
        v-if="mobileView"
        class="text-center mb-3"
        style="font-size:0.9rem;"
      >
        By proceeding you confirm you have read and accepted the terms of the <a
          href="https://www.shineinterview.com/privacy-policy/"
          target="_blank"
          style="color:#212529 !important;"
        ><span class="font-weight-bold">privacy policy</span></a>.
      </div>
      <div
        v-else
        class="text-center mb-3"
        style="font-size:0.9rem;"
      >
        By proceeding you confirm you have read and accepted the terms of the <a
          href="https://www.shineinterview.com/privacy-policy/"
          target="_blank"
          style="color:#212529 !important;"
        ><span class="font-weight-bold">privacy policy</span></a>.
      </div>
    </div>
    <div
      v-if="firstAttempt"
      class="bottom-navigation-container"
    >
      <mdb-btn
        v-if="isShineGo"
        :title="firstAttempt ? 'START' : 'RESUME'"
        :alt="firstAttempt ? 'START' : 'RESUME'"
        size="sm"
        class="btnSmall"
        color="primary"
        style="width:160px;outline:none;"
        @click="$store.commit('SET_TABDESC', 'CandidateDetails')"
      >
        {{ firstAttempt ? 'START' : 'RESUME' }}
      </mdb-btn>
      <mdb-btn
        v-else
        size="sm"
        :title="firstAttempt ? 'START' : 'RESUME'"
        :alt="firstAttempt ? 'START' : 'RESUME'"
        class="btnSmall"
        color="primary"
        style="width:160px;outline:none;"
        @click="$store.commit('SET_TABDESC', 'VideoAudioCheck')"
      >
        {{ firstAttempt ? 'START' : 'RESUME' }}
      </mdb-btn>
    </div>
    <div v-else class="bottom-navigation-container">
      <mdb-btn
        size="sm"
        :title="firstAttempt ? 'START' : 'RESUME'"
        :alt="firstAttempt ? 'START' : 'RESUME'"
        class="btnSmall"
        color="primary"
        style="width:160px;outline:none;"
        @click="$store.commit('SET_TABDESC', 'Recording')"
      >
        {{ firstAttempt ? 'START' : 'RESUME' }}
      </mdb-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'WelcomeTab',
	components: {
	},
	props: {
	},
	data () {
		return {
			mobileView: false
		}
	},
	computed: {
		...mapGetters(['isShineGo', 'job', 'candidateQuestions', 'initialUnansweredCount', 'candidate']),
		firstAttempt () {
			return this.candidateQuestions.filter(q => !q.IsPractiseQuestion && !q.IsTheScenario).filter(q => q.HasAnswered).length === 0
		},
		questionCount () {
			return this.candidateQuestions.filter(q => !q.IsPractiseQuestion && !q.IsTheScenario).length
		}
	},
	watch: {
	},
	created () {
		this.windowHeight = window.innerHeight
		this.windowWidth = window.innerWidth
		if (/mobile/i.test(navigator.userAgent)) {
			if (/ipad|iPad|tablet/i.test(navigator.userAgent)) {
				this.mobileView = false
			} else {
				this.mobileView = true
			}
		} else {
			this.mobileView = false  
		}
	},
	updated () {
	},
	methods: {}
}
</script>

<style lang="scss">
@media (max-width: 568px) {
  .outlineMobile {
    border: var(--pr-color) solid 2px;
  }
}
</style>
