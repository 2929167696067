<template>
  <div
    style="height:100%; width:100%; overflow-y:auto;background:#fff;"
    class="d-flex flex-column"
    @mouseleave="mouseLeave"
  >
    <!--header-->
    <div style="min-height:80px;height: 80px; min-width:100%;border-bottom: 1px solid;box-sizing:border-box;box-shadow: rgb(51 51 51) 0px 5px 8px -4px;z-index: 100 !important;" class="d-flex justify-content-center align-items-center nav-colour">
      <div class="p-3 p-md-3">
        <img
          :src="branding.Logo"
          style="height:5vw; max-height:55px;"
          class="ml-0 mobLogo"
          title="Logo"
          alt="Logo"
        >
      </div>
    </div>
   
    <!-- Error Pages -->
    <b-row
      v-if="errorDescription !== 'NoError'"
      no-gutters
      style="width:100%;height:100%"
      class="flex-grow-1 justify-content-center"
    >
      <div class="tabWrapper">
        <ErrorTabs />
      </div>
    </b-row>

    <!--main-->
    <b-row
      v-if="errorDescription === 'NoError'"
      no-gutters
      style="width:100%;height:100%"
      class="flex-grow-1 justify-content-center"
    >
      <div v-if="tabDesc === 'Welcome'" class="tabWrapper mobileTopPadding">
        <Welcome />
      </div>
      <div
        v-show="isShineGo"
        v-if="tabDesc === 'CandidateDetails'"
        class="tabWrapper"
      >
        <CandidateDetails />
      </div>
      <div v-if="tabDesc === 'VideoAudioCheck'" class="tabWrapper mobileTopPadding">
        <VideoAudioCheck
          :i-o-s="iOS"
          :mobile-view="mobileView"
        />
      </div>
      <div v-else-if="tabDesc === 'IntroScreen1Details'" class="tabWrapper mobileTopPadding">
        <IntroScreen1Details />
      </div>
      <div v-else-if="tabDesc === 'IntroScreen2Details'" class="tabWrapper mobileTopPadding">
        <IntroScreen2Details />
      </div>
      <div v-else-if="tabDesc === 'ShineGoPreamble'" class="tabWrapper mobileTopPadding">
        <ShineGoPreamble />
      </div>
      <div v-else-if="tabDesc === 'ShinePreamble'" class="tabWrapper mobileTopPadding">
        <ShinePreamble />
      </div>
      <div v-else-if="tabDesc.includes('RequestIDCheck')" class="tabWrapper mobileTopPadding">
        <RequestIDCheck />
      </div>
      <div
        v-else-if="tabDesc === 'Recording'"
        class="tabWrapper"
        style="position:relative"
      >
        <Recording />
      </div>
      <div v-else-if="tabDesc === 'Progress'" class="tabWrapper mobileTopPadding">
        <Progress />
      </div>
      <div v-else-if="tabDesc === 'OutroContent'" class="tabWrapper mobileTopPadding">
        <OutroContent />
      </div>
      <div v-else-if="tabDesc === 'OutroVideo'" class="tabWrapper mobileTopPadding">
        <OutroVideo />
      </div>
      <div
        v-else-if="tabDesc === 'FinalMessage'"
        class="tabWrapper"
        style="position:relative"
      >
        <FinalMessage
          :remove-closing-warning="$emit('removeClosingWarning')"
        />
      </div>
    </b-row>

    <!-- modal alert if using mobile device and orientation is landscape -->
    <mdb-modal v-if="orientationModal" id="orientationModal">
      <div
        data-v-135c83c0=""
        data-v-5c886d6e=""
        class="px-2 py-1 d-flex align-items-center modal-header"
      >
        <h5
          data-v-5c886d6e=""
          class="modal-title"
          data-v-135c83c0=""
          style="width:100%;"
        >
          <div
            data-v-5c886d6e=""
            class="logoLeft"
            style="padding: 13px 0px; margin-left: -10px;"
          >
            <div
              data-v-5c886d6e=""
              class="ml-0 ml-md-3 ml-lg-4 nav-colour p-2"
              style="border-radius: 5px;"
            >
              <img
                :src="branding.Logo"
                style="height:10vw; min-height:40px;max-height:40px;display: block;margin: auto;"
                title="Logo"
                alt="Logo"
              >
            </div>
          </div>
        </h5>
      </div>
      <mdb-modal-body>
        <p class="my-4 grey-text" style="font-size:1rem;text-align:center;height:calc(100vh - 275px);">
          To continue, please use your device in portrait mode.
        </p>
      </mdb-modal-body>
    </mdb-modal>
  </div>
</template>

<script>

import { mapGetters } from "vuex";
import Welcome from '@/components/IntroTabs/welcome.vue'
import CandidateDetails from '@/components/IntroTabs/candDetails.vue'
import VideoAudioCheck from '@/components/IntroTabs/videoAudioCheck.vue'
import IntroScreen1Details from '@/components/IntroTabs/introScreen1Details.vue'
import IntroScreen2Details from '@/components/IntroTabs/introScreen2Details.vue'
import ShineGoPreamble from '@/components/IntroTabs/shineGoPreamble.vue'
import ShinePreamble from '@/components/IntroTabs/shinePreamble.vue'
import Recording from '@/components/RecordingTabs/recording.vue'
import Progress from '@/components/CompletedTabs/progress.vue'
import OutroContent from '@/components/CompletedTabs/outroContent.vue'
import OutroVideo from '@/components/CompletedTabs/outroVideo.vue'
import FinalMessage from '@/components/CompletedTabs/finalMessage.vue'
import ErrorTabs from '@/components/IntroTabs/errorTabs.vue'
import RequestIDCheck from '@/components/IntroTabs/RequestIDCheck/requestIDCheckContainer.vue'
//import Log from "@/methods/logging.js"

export default {
	name: "Main",
	components: {
		Welcome,
		CandidateDetails,
		VideoAudioCheck,
		IntroScreen1Details,
		IntroScreen2Details,
		Recording,
		Progress,
		ShineGoPreamble,
		ShinePreamble,
		OutroContent,
		OutroVideo,
		FinalMessage,
		ErrorTabs,
		RequestIDCheck
	},
	data () {
		return {
			mobileView: false,
			orientationModal: false,
			windowWidth: 0,
			windowHeight: 0,
			mouseOut: false,
			hidden: '',
			visibilityChange: '',
			navOut: false,
			iOS: false,
			isMac: false
		}
	},
	computed: {
		...mapGetters(['isShineGo', 'tabDesc', 'branding', 'errorDescription'])
	},
	mounted() {
		window.addEventListener(
			'orientationchange',
			this.handleOrientationChange
		)   
		if (typeof document.hidden !== 'undefined') { 
			this.hidden = 'hidden'
			this.visibilityChange = 'visibilitychange'
		} else if (typeof document.msHidden !== 'undefined') {
			this.hidden = 'msHidden'
			this.visibilityChange = 'msvisibilitychange'
		} else if (typeof document.webkitHidden !== 'undefined') {
			this.hidden = 'webkitHidden'
			this.visibilityChange = 'webkitvisibilitychange'
		} 
		if (typeof document.addEventListener === 'undefined' || this.hidden === undefined) {
			console.log('A browser, such as Google Chrome or Firefox is required to support page visibility checks.')
		} else {
			document.addEventListener(this.visibilityChange, this.handleVisibilityChange, false)
		}
	},
	async created () {
		this.windowHeight = window.innerHeight
		this.windowWidth = window.innerWidth
		if (/mobile/i.test(navigator.userAgent)) {
			if (/ipad|iPad|tablet/i.test(navigator.userAgent)) {
				this.mobileView = false
				this.iOS = true
			} else {
				this.mobileView = true
				this.iOS = false
			}
		} else {
			this.mobileView = false  
			this.iOS = false
		}
		this.iOS = ['iPhone', 'iPad'].indexOf(navigator.platform) >= 0
		this.isMac = navigator.platform.indexOf('Mac') > -1
		
		if (navigator.userAgent.includes('Mac')) {
			this.isMac = true;
		}

		if (navigator.userAgent.includes('iPhone')) {
			this.iOS = true;
		}

		if (navigator.userAgent.includes('iPad')) {
			this.iOS = true;
		}
    
		if (this.isMac) { this.iOS = true }

		window.addEventListener(
			"orientationchange",
			this.handleOrientationChange
		)
		this.loaded()
	},
	methods: {
		loaded() {
			if (this.answered === false) {
				if (this.mobileView && this.windowWidth > this.windowHeight) {
					this.orientationModal = true
					this.testVideoAudioModalShowing = false
				} else {
					this.orientationModal = false
					if (this.videoAudioCheckLoaded === false && this.iOS === false) {
						this.testVideoAudioModalShowing = true
					}
				}
			}
		},
		handleOrientationChange() {
			const orientation = window.matchMedia("(orientation: portrait)").matches
			if (this.mobileView) {
				if (orientation === false) {
					this.orientationModal = false
					if (this.videoAudioCheckLoaded === false && this.iOS === false) {
						this.testVideoAudioModalShowing = true
					}
				} else {
					this.orientationModal = true
					this.testVideoAudioModalShowing = false
				}
			} else {
				this.orientationModal = false
				if (this.videoAudioCheckLoaded === false && this.iOS === false) {
					this.testVideoAudioModalShowing = true
				}
			}
		},
		mouseLeave() {
			this.$children.forEach((c) => {
				if (c.selectedQuestionIsUploading || c.blobRecording || c.recordingOn) {
					this.mouseOut = true
				}
			})
		}
	}
}
</script>

<style lang="scss" scoped>
  @media (min-width: 992px) {
    .col-lg-2 {
      flex: 0 0 20% !important;
      max-width: 20% !important;
    } 
    .col-lg-3 {
      flex: 0 0 30% !important;
      max-width: 30% !important;
    }
  }
  .popover {
    background:#C0C0C0 !important;
    border-color :#C0C0C0 transparent transparent transparent !important;
  }
  .popover_arrow::before {
    border-color :#C0C0C0 transparent transparent transparent !important;
  }
</style>

<style>
  .tabWrapper {
    height: calc(100vh - 80px);
    width:100%;
  }
  .tab {
    height:100%;
  }
  .tab-inner {
    width: 100%;
    max-width: 768px;
    min-height: calc(100% - 80px);
    flex-grow: 1;
    border-left: solid #c0c0c0 2px;
    border-right: solid #c0c0c0 2px;
    overflow-y:auto ;
  }
  @media (max-width: 578px) {
    .tab-inner {
      border:none;
    }
    .mobLogo {
      height: 60px !important;
      width: auto !important;
    }
  }
  @media (max-width: 1023px) {
    .tab-inner {
      max-width: 1023px;
      border:none;
    }
  }
  .bottom-navigation-container {
    height:80px;
    background:#c0c0c0;
    display:flex;
    width:100%;
    justify-content:center;
    align-items:center;
    box-sizing: border-box;
  }
  @media (max-width: 578px) {
    .bottom-navigation-container {
      background:#fff;
      border:none;
      border: solid #c0c0c0 2px;
    }
  }

  .btn, .btn:focus, .btn:active {
    border: none !important;
    outline: none !important;
  }
  .form-check-label {
    padding: 0 !important;
  }

@media only screen and (min-width: 577px) and (max-width: 1280px) {
  .bottom-navigation-container {
    position: fixed;
    transform: translate3d(0,0,0);
    bottom: 0px;
  }
}
</style>