<template>
  <div
    v-if="job.PressuredRetry && showDecideTimer && numberUnanswered !== 0"
  >
    <div
      v-if="retriesLeft > 0"
      class="font-weight-bold mob-pr-col"
      data-recite-translate-skip="true"
      style="font-size:0.85rem;"
    >
      <div style="float:left;">
        ANSWER WILL BE ACCEPTED IN: 
      </div>
      <span style="float:left;margin-left: 5px;" ignore="true">
        {{ decideTime }}
      </span>
    </div>
    <div
      v-if="retriesLeft == 0 && numberUnanswered !== 1"
      class="font-weight-bold mob-pr-col"
      data-recite-translate-skip="true"
      style="font-size:0.85rem;"
    >
      <div style="float:left;">
        MOVING TO NEXT QUESTION IN: 
      </div>
      <span style="float:left;margin-left: 5px;" ignore="true">
        {{ decideTime }}
      </span>
    </div>
  </div>
  <div
    v-else
  >
    <div
      v-if="allowedPreparationTime > 0 && candidateIsPreparing && !recordStart && !isPractiseQuestion && !isScenario && !showDecideTimer && !(viewingQuestionVideo && job.PressuredRetry)"
      class="font-weight-bold mob-pr-col"
      data-recite-translate-skip="true"
      style="font-size:0.85rem;"
    >
      <div style="float:left;">
        RECORDING STARTS IN: 
      </div>
      <span style="float:left;margin-left: 5px;" ignore="true">
        {{ remainingPrepTime }}
      </span>
    </div>
    <div
      v-if="recordStart && !candidateIsPreparing"
      class="font-weight-bold mob-pr-col"
      data-recite-translate-skip="true"
      style="font-size:0.85rem;"
    >
      <div style="float:left;">
        TIME REMAINING: 
      </div>
      <span style="float:left;margin-left: 5px;" ignore="true">
        {{ timeRemaining }}
      </span>
    </div>
    <!-- TIME WILL SHOW IF THE CANDIDATE HAS NOT YET STARTED TO PLAY THE VIDEO QUESTION -->
    <div
      v-if="viewingQuestionVideo && !freezeVideoQuestionPointerEvents && job.PressureWatchVideoQuestion"
      class="font-weight-bold mob-pr-col"
      data-recite-translate-skip="true"
      style="font-size:0.85rem;"
    >
      <div style="float:left;">
        PREPARATION TIME STARTS IN: 
      </div>
      <span style="float:left;margin-left: 5px;" ignore="true">
        {{ remainingPressuredVideoQuestionTime }}
      </span>
    </div>
    <div
      v-if="(retriesLeft < 11) && (retriesLeft > 0 && !recordStart && blobRecording && job.RetryCount !== 0 && !isPractiseQuestion && job.AllowRetries)"
      class="font-weight-bold mob-pr-col"
      style="font-size:0.85rem;"
    >
      YOU HAVE {{ retriesLeft }} {{ retriesLeft > 1 ? 'RETRIES' : 'RETRY' }} REMAINING
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
	name: 'Time',
	props: {
		allowedDecideTime: {
			type: Number,
			default: 300
		},
		allowedPreparationTime: {
			type: Number,
			default: 300
		},
		allowedRecordingTime: {
			type: Number,
			default: -1
		},
		blobRecording: {
			type: Blob,
			default: null
		},
		candidateIsPreparing: {
			type: Boolean
		},
		isPractiseQuestion: {
			type: Boolean
		},
		isScenario: {
			type: Boolean
		},
		numberUnanswered: {
			type: Number,
			default: 0
		},
		recordStart: {
			type: Boolean,
		},
		retriesLeft: {
			type: Number,
			default: 0
		},
		showDecideTimer: {
			type: Boolean
		},
		allowedPressureWatchVideoQuestionTime: {
			type: Number,
			default: 0
		},
		freezeVideoQuestionPointerEvents: {
			type: Boolean
		},
		viewingQuestionVideo: {
			type: Boolean
		}
	},
	computed: {
		...mapGetters(["job"]),
		decideTime() {
			var mins = Math.floor(this.allowedDecideTime / 60);
			var secs = this.allowedDecideTime % 60;
			secs = secs < 10 ? "0" + secs : secs;
			return `${mins}:${secs}`;
		},
		remainingPrepTime() {
			var mins = Math.floor(this.allowedPreparationTime / 60);
			var secs = this.allowedPreparationTime % 60;
			secs = secs < 10 ? "0" + secs : secs;
			return `${mins}:${secs}`;
		},
		remainingPressuredVideoQuestionTime () {
			var mins = Math.floor(this.allowedPressureWatchVideoQuestionTime / 60);
			var secs = this.allowedPressureWatchVideoQuestionTime % 60;
			secs = secs < 10 ? "0" + secs : secs;
			return `${mins}:${secs}`;
		},
		timeRemaining() {
			var mins = Math.floor(this.allowedRecordingTime / 60);
			var secs = this.allowedRecordingTime % 60;
			var readableSecs = secs < 10 ? "0" + secs : secs;
			if (mins < 0) {
				mins = 0;
			}
			if (secs < 0) {
				readableSecs = "00";
			}
			return `${mins}:${readableSecs}`;
		},

	}
}
</script>
