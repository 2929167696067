<template>
  <div :style="[headerStyle, primaryStyle, secondaryStyle, backgroundStyle, linkStyle, altLinkStyle, altBackgroundStyle, navStyle]" style="height:100%;">
    <div v-if="!dataLoaded && !error">
      <div style="height: 100vh;width: 100vw;max-width: 100%;max-height: 100%;transform: translateY(-50px);" class="d-flex flex-column align-items-center justify-content-center p-3 text-center">
        <mdb-icon
          icon="fa fa-pulse fa-spinner xlfont-resp grey-text"
          style="font-size: 4rem"
          title="loading icon" 
        />
      </div>
    </div>
    <div v-if="dataLoaded && !error" style="height:100%;">
      <Main
        @removeClosingWarning="removeClosingWarning()"
      />
    </div>
    <div v-if="dataLoaded && error">
      <Redirect />
    </div>
  </div>
</template>

<script>
import Redirect from "./components/redirect.vue"
import Main from "./components/main.vue"
import { mapGetters } from "vuex"
//import { ENV } from "@/common/config";
import onewayService from "@/api-services/oneway.service"

//import Log from "@/methods/logging.js"

export default {
	name: "App",
	components: {
		Redirect,
		Main
	},
	data() {
		return {
			error: false,
			dataLoaded: false,
			mobileDevice: false
		};
	},
	computed: {
		...mapGetters(["branding"]),
		backgroundStyle() {
			return {
				"--bg-color": this.branding.PrimaryColour,
			}
		},
		primaryStyle() {
			return {
				"--pr-color": this.branding.PrimaryColour,
			}
		},
		secondaryStyle() {
			return {
				"--sc-color": this.branding.SecondaryColour,
			}
		},
		headerStyle() {
			return {
				"--hd-color": this.branding.HeaderColour,
			}
		},
		linkStyle() {
			return {
				"--lk-color": this.branding.LinkColour,
			}
		},
		altLinkStyle() {
			return {
				"--al-color": this.branding.AltLkColour,
			}
		},
		altBackgroundStyle() {
			return {
				"--ab-color": this.branding.AltBgColour,
			}
		},
		navStyle() {
			return {
				"--nv-color": this.branding.NavColour,
			}
		},
	},
	updated () {
		if (this.mobileDevice) {
			document.addEventListener("visibilitychange", function() {
				if (document.hidden) {
					this.location.reload() // this reloads the interview if user toggles tabs or minimises window then maximises
				} else {
					// the page is visible
				}
			});
		}
		//if (ENV !== 'DEV') { //override if locally debugging
		//document.addEventListener("mouseleave", function() {
		//alert('Are you sure you want to do this? You may lose any responses currently being recorded or uploaded.')
		//});
		//}
	},
	created() {
		if (/mobile/i.test(navigator.userAgent)) {
			this.mobileDevice = true;
		}
		if (/ipad|iPad|tablet/i.test(navigator.userAgent)) {
			this.mobileDevice = true;
		}
		this.addLeavingWarning()
		const memory = new URLSearchParams(window.location.search).get("memory")
		if (memory === '1') {
			alert('In order to complete you need to free up memory and/or disk space on your device.\r\n\r\nWe will now resume your interview (note: you may have to answer some questions again).')
		}
		const invCode = new URLSearchParams(window.location.search).get("inv")
		if (invCode !== null) {
			this.initialiseSiteFromInviteCode(invCode)
		} else {
			this.dataLoaded = true
			this.error = true
		}
	},
	methods: {
		async initialiseSiteFromInviteCode(invCode) {
			try {
				const res = await onewayService.initialiseOneWayData(invCode)
				this.$store.commit("INITIALISE", res.data)
				this.dataLoaded = true
				res.data === false ? this.error = true : this.error = false
			} catch (err) {
				this.dataLoaded = true
				this.$store.commit('SET_ERROR_PAGE')
				console.log(err, Object.assign({}, err))
			}
		},
		removeClosingWarning () {
			window.onbeforeunload = () => console.log('closing')
		},
		addLeavingWarning () {
			window.onbeforeunload = e => this.leavingWarning(e)
		},
		leavingWarning: function  (e) {
			//const invCode = new URLSearchParams(window.location.search).get("inv")
			//Log.debug("User has attempted to unload the site : ", 0, invCode, "InvitationCode")
			e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
			e.returnValue = ''; // Chrome requires returnValue to be set
		}
	}
}
</script>

<style lang="scss">
  @import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap");
  @import url("https://fonts.googleapis.com/css?family=Raleway");
  $image-path: "~@/../mdb/mdbvue/img";
  @import "~@/../mdb/mdbvue/scss/mdb-pro.scss";
  body {
    font-family: "Raleway", sans-serif !important;
  }
  .close {
    color: #c0c0c0 !important;
    opacity: 1 !important;
  }
  .recite-logo div {
    float: right;
    display: none;
 }
</style>
