<template>
  <div class="tab">
    <div class="tab-inner mx-auto d-flex flex-column align-items-center autoScroll">
      <div class="d-flex align-items-center mdfont-resp justify-content-center" style="font-style:italic;width:100%; height:40px; color:#fff; background:#333; z-index:10">
        <span>ID CHECK</span>
      </div>
      <div class="p-4 d-flex align-items-center justify-content-center justify-content-md-start text-center flex-column flex-grow-1 mb-5">
        <div class="mb-3" style="font-size:0.9rem;">
          You will need to provide identification to progress in your application.
        </div>
        <div class="mb-3" style="font-size:0.9rem;">
          <span class="font-weight-bold">{{ job.ClientName }}</span> will be in touch after your interview about this.
        </div>
      </div>
    </div>
    <div class="bottom-navigation-container">
      <mdb-btn
        size="sm"
        title="BACK"
        class="btnSmall"
        color="primary"
        style="width:160px;outline:none;"
        @click="$store.commit('SET_TABDESC', 'RequestIDCheck-CaptureID')"
      >
        BACK
      </mdb-btn>
      <mdb-btn
        size="sm"
        title="NEXT"
        alt="NEXT"
        class="btnSmall"
        color="primary"
        style="width:160px;outline:none;"
        @click="goToNextPage()"
      >
        NEXT
      </mdb-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'NoIDMessage',
	data () {
		return {}
	},
	computed: {
		...mapGetters(['job', 'candidate', 'tabDesc']),
		skipIntroScreen1Details () {
			return this.job.IntroScreen1VideoUrl == "" && (this.job.IntroScreen1 === undefined || this.job.IntroScreen1 === "" || this.job.IntroScreen1 === null)
		}
	},
	methods: {
		async goToNextPage () {
			this.$store.commit('SET_TABDESC', 'Recording')
		},
	}
}
</script>

<style lang="scss">

</style>
