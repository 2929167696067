import Vue from "vue"
import App from "./App.vue"
import store from "./store"
import * as mdbvue from "mdbvue"
import {
	BTable,
	BCol,
	BFormGroup,
	BInputGroup,
	BButton,
	BInputGroupAppend,
	BFormInput,
	VBToggle,
	BRow,
	BootstrapVue,
} from "bootstrap-vue"

import "mdbvue/lib/mdbvue.css"
import "@fortawesome/fontawesome-free/css/all.min.css"
import "bootstrap/dist/css/bootstrap.css"
import "bootstrap-vue/dist/bootstrap-vue.css"
import "../src/common/styles.css"

import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'
Vue.use(Cropper)

Vue.use(BootstrapVue)

for (const component in mdbvue) {
	Vue.component(component, mdbvue[component])
}

Vue.component("b-table", BTable)
Vue.component("b-button", BButton)
Vue.component("b-col", BCol)
Vue.component("b-row", BRow)
Vue.component("b-form-group", BFormGroup)
Vue.component("b-form-input", BFormInput)
Vue.component("b-input-group", BInputGroup)
Vue.component("b-button", BButton)
Vue.component("b-input-group-append", BInputGroupAppend)
Vue.component("b-toggle", VBToggle)

new Vue({
	store,
	render: (h) => h(App),
}).$mount("#app");
