import loggingService from "@/api-services/logging.service"

export default {
	trace: async function(message, accountId = 0, identifier, identifierType = 1) {
		var logging = {
			LogLevel: "Trace",
			Message: message,
			AccountId: accountId,
			Identifier: identifier,
			IdentifierType: convertIdentifierTypeEnum(identifierType)
		}

		await loggingService.log(logging)
	},

	debug: async function(message, accountId = 0, identifier, identifierType) {
		var logging = {
			LogLevel: "Debug",
			Message: message,
			AccountId: accountId,
			Identifier: identifier,
			IdentifierType: convertIdentifierTypeEnum(identifierType)
		}
    
		await loggingService.log(logging)
	},

	info: async function(message, accountId = 0, identifier, identifierType) {
		var logging = {
			LogLevel: "Info",
			Message: message,
			AccountId: accountId,
			Identifier: identifier,
			IdentifierType: convertIdentifierTypeEnum(identifierType)
		}
    
		await loggingService.log(logging)
	},

	warn: async function(message, accountId = 0, identifier, identifierType) {
		var logging = {
			LogLevel: "Warn",
			Message: message,
			AccountId: accountId,
			Identifier: identifier,
			IdentifierType: convertIdentifierTypeEnum(identifierType)
		}
    
		await loggingService.log(logging)
	},

	error: async function(message, accountId = 0, identifier, identifierType) {
		var logging = {
			LogLevel: "Error",
			Message: message,
			AccountId: accountId,
			Identifier: identifier,
			IdentifierType: convertIdentifierTypeEnum(identifierType)
		}
		await loggingService.log(logging)
	}
}

const convertIdentifierTypeEnum = string => {
	let num = 0;
	switch (string) {
	case "None":
		num = 0;
		break;
	case "InvitationCode":
		num = 1;
		break;
	case "CandidateAccountId":
		num = 2;
		break;
	default:
		break;
	}
	return num;
}