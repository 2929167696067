<template>
  <div class="d-flex align-items-center justify-content-center">
    <b-row
      v-show="!isScenario"
      v-if="viewingQuestionVideo && !blobRecording"
      no-gutters
      align-h="center"
      align-v="center"
      style="height:100%;"
    >
      <mdb-btn
        v-if="allowedPreparationTime <= 0"
        title="NEXT"
        alt="RE-RECORD"
        size="sm"
        class="btnSmall"
        color="primary"
        style="width:fit-content;outline:none !important;"
        @click="$emit('goToPrepTimeFromVideoQuestion')"
      >
        NEXT
      </mdb-btn>
    </b-row>
    <!-- <b-row
      v-show="!isScenario"
      v-if="!viewingQuestionVideo && !blobRecording"
      no-gutters
      align-h="center"
      align-v="center"
      style="height:100%;"
    >
      <mdb-btn
        v-if="allowedPreparationTime > 0"
        size="sm"
        class="btnSmall"
        color="primary"
        style="width:fit-content;outline:none !important;"
        @click="$emit('goToPrepTimeFromVideoQuestion')"
      >
        BEGIN PREPARATION TIME
      </mdb-btn>
    </b-row> -->
    <mdb-btn
      v-if="(job.AllowRetries && retriesLeft > 0 || isPractiseQuestion) && !recordStart && blobRecording"
      title="RE-RECORD"
      alt="RE-RECORD"
      size="sm"
      class="btnSmall"
      color="primary"
      style="width:160px;outline:none !important;padding-left: 1rem !important;padding-right: 1rem !important;"
      @click="$emit('resetVideo', true)"
    >
      <i class="fa fa-circle" /> RE-RECORD
    </mdb-btn>
    <mdb-btn
      v-if="!recordStart && !blobRecording && !viewingQuestionVideo && candidateIsPreparing && !isScenario"
      title="START RECORDING"
      alt="START RECORDING"
      size="sm"
      class="btnSmall"
      color="primary"
      :disabled="!stream"
      style="width:160px;outline:none !important;padding-left: 1rem !important;padding-right: 1rem !important;"
      @click="$emit('startRecord')"
    >
      <i class="fa fa-circle" /> START RECORDING
    </mdb-btn>

    <mdb-btn
      v-if="recordStart && !blobRecording"
      title="STOP"
      alt="STOP"
      size="sm"
      class="btnSmall"
      color="primary"
      :disabled="showStop === false"
      style="width:160px;outline:none !important;padding-left: 1rem !important;padding-right: 1rem !important;"
      @click="$emit('stopRecord')"
    >
      <i class="fa fa-square" /> STOP
    </mdb-btn>
        

    <!-- next question -->
    <mdb-btn
      v-if="!recordStart && blobRecording && numberUnanswered > 1"
      size="sm"
      title="NEXT QUESTION"
      alt="NEXT QUESTION"
      class="btnSmall"
      color="primary"
      style="width:160px;outline:none !important;padding-left: 1rem !important;padding-right: 1rem !important;"
      @click="$emit('uploadCurrentVideoAndGoToNextQuestion');$emit('stopVid')"
    >
      NEXT QUESTION
    </mdb-btn>
    <mdb-btn
      v-show="!isPractiseQuestion"
      v-if="!recordStart && blobRecording && numberUnanswered === 1"
      title="FINISH"
      alt="FINISH"
      size="sm"
      class="btnSmall"
      color="primary"
      style="width:160px;outline:none !important;padding-left: 1rem !important;padding-right: 1rem !important;"
      @click="$emit('uploadCurrentVideoAndGoToNextQuestion');$emit('stopVid')"
    >
      FINISH
    </mdb-btn>
    <mdb-btn
      v-show="isPractiseQuestion"
      v-if="!recordStart && blobRecording && numberUnanswered === 1"
      title="NEXT"
      alt="NEXT"
      size="sm"
      class="btnSmall"
      color="primary"
      style="width:160px;outline:none !important;padding-left: 1rem !important;padding-right: 1rem !important;"
      @click="$emit('uploadCurrentVideoAndGoToNextQuestion');$emit('stopVid')"
    >
      NEXT
    </mdb-btn>
    <mdb-btn
      v-if="isScenario"
      title="BEGIN SCENARIO QUESTIONS"
      alt="BEGIN SCENARIO QUESTIONS"
      size="sm"
      class="btnSmall"
      color="primary"
      style="width:180px;outline:none !important;padding-left: 1rem !important;padding-right: 1rem !important;"
      @click="$emit('beginScenarioQuestions')"
    >
      BEGIN SCENARIO QUESTIONS
    </mdb-btn>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
	name: 'RecordButtons',
	props: {
		allowedPreparationTime: {
			type: Number,
			default: 300
		},
		blobRecording: {
			type: Blob,
			default: null
		},
		candidateIsPreparing: {
			type: Boolean
		},
		isPractiseQuestion: {
			type: Boolean
		},
		isScenario: {
			type: Boolean
		},
		numberUnanswered: {
			type: Number,
			default: 0
		},
		recordStart: {
			type: Boolean,
		},
		retriesLeft: {
			type: Number,
			default: 0
		},
		showStop: {
			type: Boolean
		},
		stream: {
			type: MediaStream,
			default: null
		},
		viewingQuestionVideo: {
			type: Boolean
		}
	},
	computed: {
		...mapGetters(["job"])
	}
}

</script>