import Axios from "axios";
import { API_URL2 } from "@/common/config";
export default class onewayService {

	constructor() {
		Axios.defaults.headers.common["Access-Control-Allow-Origin"] = API_URL2;
	}
  
	static async initialiseOneWayData (invitationCode) {
		return Axios.get(API_URL2 + `/one_way_api/validation/token/${invitationCode}`)
	}

	static async profilePictureAnon (invitationCode, formData) {
		return Axios.post(API_URL2 + `/one_way_api/candidate_profile/profile_picture/${invitationCode}`, formData, { headers: { 'Content-Type': 'multipart/form-data'} })
	}

	static async editMyDetails (JSONData) {
		return Axios.post(API_URL2 + '/one_way_api/candidate_profile/edit_my_details', JSONData)
	}

	static async addID (formData) {
		return Axios.post(API_URL2 + '/one_way_api/candidate_profile/add_id', formData, { headers: { 'Content-Type': 'multipart/form-data'} })
	}

	static async lockInterview (invitationCode) {
		return Axios.post(API_URL2 + `/one_way_api/candidate_profile/lock_interview/${invitationCode}`)
	}

	static async unlockInterview (invitationCode) {
		return Axios.post(API_URL2 + `/one_way_api/candidate_profile/unlock_interview?inviteCode=${invitationCode}`)
	}

	static async answeredPractice (invitationCode) {
		return Axios.post(API_URL2 + `/one_way_api/upload/answered_practice/${invitationCode}`)
	}

	static async getInTouch (invitationCode) {
		return Axios.get(API_URL2 + `/one_way_api/validation/get_in_touch/${invitationCode}`)
	}

	static async candidateQuestionRetry (invitationCode, questionId) {
		return Axios.post(API_URL2 + `/one_way_api/upload/candidate_question_retry?invitationCode=${invitationCode}&goCandidateQuestionId=${questionId}`)
	}

	static async flagReciteMeToolBarAsActive (invitationCode) {
		return Axios.post(API_URL2 + `/one_way_api/upload/recite_me_toolbar_active/${invitationCode}`)
	}
}
