<template>
  <div class="tab">
    <div class="tab-inner mx-auto p-3 p-lg-4 pb-2 d-flex flex-column align-items-center justifyContent" style="overflow:auto; max-height: calc(100vh - 160px)">
      <div class="text-center mb-4 font-weight-bold">
        Uploading your answers
      </div>
      <div class="mb-3 mobCenter" style="padding-bottom:10px;font-size:0.9rem;">
        Please wait while we upload your answers. When all answers have reached 100%, please click 'NEXT'.
      </div>
      <!-- Progress bars -->
      <div
        style="width:100%;height:fit-content;"
        class="mb-3 tabWidth"
      >
        <div
          v-for="(question, i) in questions"
          :key="i"
          style="height:50px"
          class="d-flex align-items-center justify-content-center pr-1"
        >
          <div
            v-if="question.Percentage > 99"
            style="border-radius:50%;height:32px;width:32px;border: solid #333 2px; background: var(--pr-color)"
            class="d-flex align-items-center justify-content-center"
          >
            <i class="fa fa-check" />
          </div>

          <div v-if="question.Percentage === 0 && question.UploadState !== 'PreparingUpload' && question.UploadState !== 'Failed'" style="border-radius:50%;border: solid #333 4px;height:32px; width: 32px" />

          <div v-if="(question.Percentage < 100 && question.Percentage !== 0) && question.UploadState === 'Uploading' " style="border-radius:50%;height:32px;width:32px;">
            <i class="fa fa-spinner fa-pulse fa-2x" style="transform:scale(1.2)" />
          </div>
          
          <div v-if="question.UploadState !== 'PreparingUpload' && question.UploadState !== 'Failed' && question.UploadState !== 'PreviouslyAnswered'" class="mx-3">
            <span class="font-weight-bold d-inline-block d-md-none text-nowrap">Q {{ i + 1 }}</span>
            <span class="font-weight-bold d-none d-md-inline-block">Question {{ i + 1 }}</span>
          </div>
          
          <div
            v-if="question.UploadState === 'PreparingUpload'"
            style="border-radius:50%;height:fit-content;width:fit-content;"
            class="d-flex align-items-center justify-content-center"
          >
            <i class="fa fa-circle-notch fa-spin fa-2x" style="height:fit-content" />
          </div>
          
          <div v-if="question.UploadState === 'PreparingUpload' && question.Percentage === 0" class="ml-3 mobShow mx-3">
            <span class="font-weight-bold d-inline-block d-md-none text-nowrap">Q{{ i + 1 }}</span>
            <span class="font-weight-bold d-none d-md-inline-block">Question {{ i + 1 }}</span>
          </div>

          <div v-if="question.UploadState === 'PreviouslyAnswered'" class="ml-3 mobShow mx-3">
            <span class="font-weight-bold d-inline-block d-md-none text-nowrap">Q{{ i + 1 }}</span>
            <span class="font-weight-bold d-none d-md-inline-block">Question {{ i + 1 }}</span>
          </div>

          <div
            v-if="question.UploadState === 'PreparingUpload' && question.Percentage === 0"
            class="mobHide"
            style="margin-left:-10px;"
          >
            <span class="font-weight-bold" style="font-size: smaller;">(preparing upload)</span>
          </div>

          <div
            v-if="question.UploadState === 'PreviouslyAnswered'"
            class="mobHide"
            style="margin-left:-10px;"
          >
            <span class="font-weight-bold" style="font-size: smaller;">(previously answered)</span>
          </div>
          
          <div
            v-if="question.UploadState === 'Failed'"
            style="border-radius:50%;border: solid #333 4px;height:32px; width: 32px;background:red;"
            class="d-flex align-items-center justify-content-center"
            @click="restartUpload(question.QuestionId)"
          >
            <i class="fa fa-times" />
          </div>
          
          <div
            v-if="question.UploadState === 'Failed'"
            class="ml-3"
            style="cursor:pointer"
            @click="restartUpload(question.QuestionId)"
          >
            <span class="font-weight-bold d-inline-block d-md-none text-nowrap">Q{{ i + 1 }}</span>
            <span class="font-weight-bold d-none d-md-inline-block">Question {{ i + 1 }} (click to retry)</span>
          </div>
          
          <div style="position:relative;border: solid 3px #c0c0c0;width:40%;height:20px; border-radius: 8px" class="mqw d-flex align-items-center justify-content-between ml-auto">
            <div
              style="position:absolute; left:1%; top:15%; height: 70%; background: var(--pr-color); border-radius: 3px;max-width:98%;"
              :style="{'width' : `${Math.floor(question.Percentage)}%`}"
              class="smoothWidth"
            />
          </div>
          <div class="mx-3" style="width:20px;">
            {{ question.Percentage > 100 ? 100 : Math.floor(question.Percentage) }}%
          </div>
        </div>
      </div>
    
      <div class="mobDisplay" style="margin-top:20px;text-align:center;font-size:0.9rem;margin-bottom:20px;">
        <span>In the event your uploads appear to freeze, click on the failed question(s) above to retry uploading. If that still does not work, please wait 30 seonds and then </span>
        <a
          href="#"
          class="primary-colour"
          @click="restartAllFailedUploads()"
        >
          <strong>CLICK HERE</strong>
        </a> to resume. If your upload still fails or there is no response, please refresh and the system will notify you if you will need to answer any missing questions again.<br><br>
      </div>
      <div class="webDisplay" style="margin-top:20px;text-align:center;font-size:0.9rem;">
        In the event your uploads appear to freeze, click on the failed question(s) above to retry uploading. If that still does not work, please wait 30 seconds and then <a
          href="#"
          class="primary-colour"
          @click="restartAllFailedUploads()"
        ><strong>CLICK HERE</strong></a> to resume.<br><br>If any of your uploads still fails, please refresh and the system will notify you if you will need to answer any missing questions again.
        <br><br>
        If after all of this you are still having issues, please 
        <a 
          class="primary-colour"
          href="https://support.shineinterview.com/hc/en-us/articles/29858095985297-Troubleshooting"
          target="_blank"
        >
          <strong>CLICK HERE</strong>
        </a> to read through our troubleshooting guidelines.
      </div>
    </div>
    <div class="bottom-navigation-container">
      <mdb-btn
        size="sm"
        title="NEXT"
        alt="NEXT"
        class="btnSmall"
        color="primary"
        :disabled="incompleteUploadExists"
        style="width:160px;outline:none;"
        @click="$store.commit('SET_TABDESC', 'OutroContent')"
      >
        NEXT
      </mdb-btn>
    </div>
    <!--used by mutation observer to disable ability to close accessiblility toolbar-->
    <div id="locked-page" style="display:none;" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import UploadFile from '@/upload'
//import Log from "@/methods/logging.js"
import onewayService from "@/api-services/oneway.service"

export default {
	name: 'Progress',
	data () {
		return {
			isVideo: false
		}
	},
	async mounted () {
		await onewayService.unlockInterview(this.invitationCode)
	},
	computed: {
		...mapGetters(['uploadPercentageArray', 'candidateQuestions', 'blobs', 'invitationCode']),
		questions () {
			return this.candidateQuestions.filter(q => q.IsPractiseQuestion === false && q.ScenarioTitle === 'N/A').map(q => { return {...q, Percentage: this.getPercentage(q.QuestionId), UploadState: this.getUploadState(q.QuestionId)} })
		},
		allCompleted () {
			return this.uploadPercentageArray.filter(p => p.Percentage < 100).length === 0
		},
		incompleteUploadExists () {
			return this.questions.filter(q => q.Percentage < 100).length > 0
		},
		currentlyUploadingExists () {
			return this.questions.filter(q => q.UploadState === 'PreparingUpload').length > 0
		}
	},
	methods: {
		getPercentage (qId) {
			const q = this.uploadPercentageArray.filter(q => q.QuestionId === qId)[0]
			if (q.BlockTotal !== 0) {
				return Math.floor((q.BlockCount / q.BlockTotal) * 100)
			} else { return 0 }
		},
		getUploadState (qId) {
			const q = this.uploadPercentageArray.filter(q => q.QuestionId === qId)[0]
			return q.UploadState
		},
		async restartUpload (qId) {
			//await Log.debug("Restart Upload has been pressed for questionId: " + qId, 0, this.invitationCode, "InvitationCode")
			var data
			try {
				data = this.blobs.filter(b => b.questionId === qId)[0].blob
			} catch (err) {
				//await Log.error("An error occurred when trying to get the blob - " + err.toString(), 0, this.invitationCode, "InvitationCode")
				return
			}
			const vData = {
				InvitationCode: this.invitationCode, // or whatever array and naming convenrtion this information is containted within
				questionId: qId, // or whatever array and naming convenrtion this information is containted within
			}
			this.$store.commit('CHANGE_HAS_ANSWERED', { QuestionId: qId })
			this.$store.commit('CHANGE_UPLOAD_STATE', { QuestionId: qId, UploadState: 'PreparingUpload' })

			this.uploadStarted = true

			try {
			//await Log.debug("Starting to upload the video from a restart for questionId: " + qId, 0, this.invitationCode, "InvitationCode")
				const uploadSuccess = await UploadFile.uploadVideo(0, data, vData, this.updateUploadProgress, true)
				if(uploadSuccess) {
					this.$store.commit('UPDATE_UPLOAD_PERCENTAGE', { QuestionId: qId, BlockTotal: null })
					this.$store.commit('CHANGE_UPLOAD_STATE', { QuestionId: qId, UploadState: 'Finalizing' })
					this.$store.commit('ADD_REMOVE_TEMP_BLOB', { isAdding: false, blob: null, questionId: qId })
				} else { throw 'Upload Failed' }
			} catch (err) {
				//await Log.error("An error occurred when trying to upload the video - " + err.toString(), 0, this.invitationCode, "InvitationCode")
				this.$store.commit('CHANGE_UPLOAD_STATE', { QuestionId: qId, UploadState: 'Failed' })
			}
		},
		async restartAllFailedUploads () {
			//await Log.debug("Restarting all failed uploads", 0, this.invitationCode, "InvitationCode")
			const failedUploads = this.questions.filter(q => q.Percentage < 100 || q.UploadState === 'Failed')
			failedUploads.map(q => this.restartUpload(q.QuestionId))
		},
		updateUploadProgress (blockTotal, questionId) {
			//Log.trace("Updating upload progress", 0, this.invitationCode, "InvitationCode")
			if(blockTotal === 'Failed') {
				this.$store.commit('CHANGE_UPLOAD_STATE', { QuestionId: questionId, UploadState: 'Failed' })
				return
			}
			this.$store.commit('CHANGE_UPLOAD_STATE', { QuestionId: questionId, UploadState: 'Uploading' })
			this.$store.commit('UPDATE_UPLOAD_PERCENTAGE', { QuestionId: questionId, BlockTotal: blockTotal + 1 })
		}
	}
}
</script>

<style lang="scss" scoped>
.smoothWidth {
  transition: all 2s ease ;
}
.mobCenter {
  text-align:center;
}
</style>
