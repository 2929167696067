<template>
  <div class="tab">
    <div class="tab-inner mx-auto p-3 p-lg-4 d-flex flex-column align-items-center justifyContent autoScroll">
      <div class="text-center mb-4 font-weight-bold" style="font-size:0.9rem;">
        About your video interview
      </div>
      <div
        v-if="page === 1"
        class="d-flex align-items-start justify-content-start mb-2 pd-1"
        style="width:100%;"
      >
        <i class="fa fa-check mr-3 p-1" style="height:fit-content; background:var(--pr-color);border-radius:50%;color:var(--lk-color);" />
        <div style="font-size:0.9rem;">
          {{ job.InvitationIntroductionStep0 }}
        </div>
      </div>
      <div
        v-if="page === 1"
        class="d-flex align-items-start justify-content-start mb-2 pd-1"
        style="width:100%;"
      >
        <i class="fa fa-check mr-3 p-1" style="height:fit-content; background:var(--pr-color);border-radius:50%;color:var(--lk-color);" />
        <div style="font-size:0.9rem;">
          {{ job.InvitationIntroductionStep1 }}
        </div>
      </div>
      <div
        v-if="page === 2"
        class="d-flex align-items-start justify-content-start mb-2 pd-1"
        style="width:100%;"
      >
        <i class="fa fa-check mr-3 p-1" style="height:fit-content; background:var(--pr-color);border-radius:50%;color:var(--lk-color);" />
        <div style="font-size:0.9rem;">
          {{ job.InvitationIntroductionStep2 }}
        </div>
      </div>
      <div
        v-if="page === 2"
        class="d-flex align-items-start justify-content-start mb-2 pd-1"
        style="width:100%;"
      >
        <i class="fa fa-check mr-3 p-1" style="height:fit-content; background:var(--pr-color);border-radius:50%;color:var(--lk-color);" />
        <div style="font-size:0.9rem;">
          {{ job.InvitationIntroductionStep3 }}
        </div>
      </div>
      <div
        v-if="page === 3"
        class="d-flex align-items-start justify-content-start mb-2 pd-1"
        style="width:100%;"
      >
        <i class="fa fa-check mr-3 p-1" style="height:fit-content; background:var(--pr-color);border-radius:50%;color:var(--lk-color);" />
        <div style="font-size:0.9rem;">
          {{ job.ReadyToStartContent }}
        </div>
      </div>
    </div>
    <div class="bottom-navigation-container">
      <mdb-btn
        v-if="page === 1"
        title="NEXT"
        alt="NEXT"
        size="sm"
        class="btnSmall"
        color="primary"
        style="width:160px;outline:none;"
        @click="page = 2"
      >
        NEXT
      </mdb-btn>
      <mdb-btn
        v-if="page === 2"
        title="NEXT"
        alt="NEXT"
        size="sm"
        class="btnSmall"
        color="primary"
        style="width:160px;outline:none;"
        @click="secondNext()"
      >
        NEXT
      </mdb-btn>
      <mdb-btn
        v-if="page === 3"
        title="NEXT"
        alt="NEXT"
        size="sm"
        class="btnSmall"
        color="primary"
        style="width:160px;outline:none;"
        @click="$store.commit('SET_TABDESC', 'Recording')"
      >
        NEXT
      </mdb-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'ShinePreamble',
	components: {
	},
	props: {
	},
	data () {
		return {
			page: 1
		}
	},
	computed: {
		...mapGetters(['job', 'position'])
	},
	watch: {
	},
	created () {
	},
	updated () {
	},
	methods: {
		secondNext () {
			if(this.position > 0) {
				this.$store.commit('SET_TABDESC', 'Recording')
			} else {
				this.page = 3
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.shinePreambleBubbles {
  padding: 10px;
  border-radius: 5px;
  background: #c0c0c0;
}
@media (min-width: 577px) {
  .pd-1 {
    padding: 0.5rem !important;
  }
}
</style>
