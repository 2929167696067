<template>
  <div class="tab finalMessagePageHeight">
    <div class="tab-inner mx-auto p-3 p-lg-4 d-flex flex-column align-items-center justifyContent finalMessagePageHeight">
      <div class="mb-3 mt-2 font-weight-bold" style="text-align:center;font-size:0.9rem;">
        Well done, you've completed the interview!
        <br><br>
        <span style="font-weight:normal;">You may now close your browser window/tab.</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'FinalMessage',
	data () {
		return {}
	},
	computed: {
		...mapGetters(['job', 'candidateQuestions', 'initialUnansweredCount', 'candidate'])
	},
	beforeMount () {
		try {
			var scriptTag = 'var closeElement = document.getElementById("recite-close");'
			scriptTag += '    if (closeElement != null) {'
			scriptTag += '      document.getElementById("recite-close").style.display = "block";'
			scriptTag += '    }'
			scriptTag += '    var newMutationObserver = new MutationObserver(function(mutations) {'
			scriptTag += '    mutations.forEach(function(mutation) {'
			scriptTag += '      var closeModalContent = document.getElementById("recite-modal-content");'
			scriptTag += '      if (closeModalContent !== null) {'
			scriptTag += '        document.getElementById("recite-modal").style.height="220px";'
			scriptTag += '        document.getElementById("recite-modal-content").style.height="100px";'
			scriptTag += '	      var str = "Are you sure you want to close the accessibility toolbar?";'
			scriptTag += '	      closeModalContent.innerHTML = str;'
			scriptTag += '	      newMutationObserver.disconnect();'
			scriptTag += '      }'
			scriptTag += '    });'
			scriptTag += '  });'
			scriptTag += '  newMutationObserver.observe(document.documentElement, { attributes: true, subtree: true });'
			var g = document.createElement('script')
			g.type = 'text/javascript'
			g.async = true
			var s = document.getElementsByTagName('script')[0]
			g.text = scriptTag
			s.parentElement.insertBefore(g, s)
		}
		catch (err) {
			//?
		}
	},
	mounted () {
		this.$emit('removeClosingWarning')
	}
}
</script>

<style lang="scss">
@media (max-width: 568px) {
  .outlineMobile {
    border: var(--pr-color) solid 2px;   
  }
  .noMobShow{
    display:none !important;
  }
}
.finalMessagePageHeight {
   height:calc(100vh - 80px) !important;
}
</style>
