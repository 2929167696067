<template>
  <div id="outer" style="background: white !important;height:100%; width:100%;overflow:hidden;">
    <div style="width: 100%;height:80px;background:#ff7e00;" class="d-flex align-items-center justify-content-center">
      <img 
        src="https://go.shineinterview.com/img/shine-logo-white.png"
        style="height:66px;padding:5px;border:none;"
        alt="Shine Logo"
        title="Shine Logo"
      >
    </div>

    <!--desktop-->
    <div
      v-if="mobileView === false"
      id="inner"
      style="height:100%"
    >
      <div style="background:#f1f1f1;width:600px;border:solid 1px #c0c0c0;padding:20px;height:492px;padding-top:30px;height: 385px;margin-top: 80px;margin-left: 1rem;" class="mr-3">
        <p class="primary-colour" style="font-size: 14px; margin-bottom: 40px;font-weight:bold;text-align:center;">
          Nothing to see here!
        </p>
        <div style="background:#fefefe;width:550px;border:solid 1px #c0c0c0;padding:20px;padding-bottom:0px;height:290px;margin-top:-20px;">
          <div style="height:100%" class="text-center d-flex flex-column align-items-center justify-content-center">
            <mdb-icon
              far
              icon="frown"
              class="frownPadding primary-colour mt-5"
              style="text-align: center; font-size: 4em;"
              aria-hidden="true"
              title="Sorry, nothing to see here"
              alt="Sorry, nothing to see here"
            >
              <span class="sr-only">
                Sorry, nothing to see here
              </span>
            </mdb-icon>
          </div>
        </div>
      </div>
    </div>

    <!--mobile-->
    <div
      v-else
      class="d-flex flex-column align-items-center justify-content-center d-lg-none text-center p-2 p-sm-3 mx-auto"
      style="height:100%;max-width:500px;margin-top:50px;"
    >
      <p class="primary-colour" style="font-size:16px;font-weight:bold;margin-bottom:40px;">
        Nothing to see here!
      </p>
      <mdb-icon
        far
        icon="frown"
        class="frownPadding primary-colour"
        style="text-align: center; font-size: 8em !important;"
        aria-hidden="true"
      >
        <span class="sr-only">
          Sorry, nothing to see here
        </span>
      </mdb-icon>
    </div>
  </div>
</template>
<script>
export default {
	name: "Redirect",
	components: {},
	data () {
		return {
			mobileView: false
		}
	},
	created () {
		if (/mobile/i.test(navigator.userAgent)) {
			if (/ipad|iPad|tablet/i.test(navigator.userAgent)) {
				this.mobileView = false
			} else {
				this.mobileView = true
			}
		} else {
			this.mobileView = false
		}
	}
};
</script>

<style lang="scss" scoped>
  .frownPadding {
    margin-top:-15px !important;
    font-size: 10rem !important;
  }
  #inner {
    display: table;
    margin: 0 auto;
  }
  #outer {
    width: 100%;
  }
</style>
