<template>
  <div
    v-if="showMicrophoneTest"
    style="height:100%; width:100%;"
    class="d-flex align-items-end justify-content-start audioHardwareHeight"
  >
    <div
      style="height:25%; width:20%; border:solid 1px #333"
      class="mx-2"
      :style="{'background-color' : audioBoxesToFill > 0 ? 'green' : 'white'}"
    />
    <div
      style="height:50%; width:20%; border:solid 1px #333"
      class="mx-2"
      :style="{'background-color' : audioBoxesToFill > 1 ? 'green' : 'white'}"
    />
    <div
      style="height:75%; width:20%; border:solid 1px #333"
      class="mx-2"
      :style="{'background-color' : audioBoxesToFill > 2 ? 'green' : 'white'}"
    />
    <div
      style="height:100%; width:20%; border:solid 1px #333"
      class="mx-2"
      :style="{'background-color' : audioBoxesToFill > 3 ? 'green' : 'white'}"
    />
  </div>
</template>

<script>
// import { mapGetters, mapActions, mapMutations } from 'vuex'

export default {
	name: 'MicrophoneTest',
	components: {
	},
	props: {
		iOS: Boolean
	},
	data () {
		return {
			showMicrophoneTest: false,
			analyserNode: null,
			audioContext: null,
			audioInterval: null,
			mediaStreamAudioSourceNode: null,
			stream: null,
			volume: 0,
			videoWorking: false,
			mobileView: false,
			windowHeight: 0,
			windowWidth: 0
		}
	},
	computed: {
		audioBoxesToFill () {
			return this.volume < 0.001 ? 0 : this.volume < 0.01 ? 1 : this.volume < 0.05 ? 2 : this.volume < 0.1 ? 3 : 4
		},
		audioWorking () {
			return this.volume > 0 && this.AudioContext !== null
		}
	},
	created () {
		// if (!this.iOS) {
		this.getMicStatus()
		// }
	},
	methods: {
		onFrame () {
			const pcmData = new Float32Array(this.analyserNode.fftSize)
			this.analyserNode.getFloatTimeDomainData(pcmData)
			let sumSquares = 0.0
			for (const amplitude of pcmData) { sumSquares += amplitude*amplitude; }
			this.volume = Math.sqrt(sumSquares / pcmData.length)
		},
		async getMicStatus () {
			try {
				this.mstream = await navigator.mediaDevices.getUserMedia({
					video: false,
					audio: true
				})

				// audio
				var AudioContext = window.AudioContext || window.webkitAudioContext
				this.audioContext = new AudioContext()
				this.mediaStreamAudioSourceNode = this.audioContext.createMediaStreamSource(this.mstream)
				this.analyserNode = this.audioContext.createAnalyser()
				this.mediaStreamAudioSourceNode.connect(this.analyserNode)

				// video
				this.audioInterval = setInterval(() => {
					var requestAnimationFrame = window.requestAnimationFrame || window.requestAnimationFrame || window.mozRequestAnimationFrame || window.webkitRequestAnimationFrame || window.msRequestAnimationFrame
					requestAnimationFrame(this.onFrame)
					if (this.mstream.active) {
						this.videoWorking = true
					} else { this.videoWorking = false }
				}, 100)

				this.$emit('showAlternativeMicMessage', false)
				this.showMicrophoneTest = true
			} catch (err) {
				this.$emit('showAlternativeMicMessage', true)
				this.showMicrophoneTest = false
			}
		},
		async turnOffMic () {
			const tracks = this.mstream.getAudioTracks();
			tracks.forEach(function(track) {
				track.stop();
			});
			this.audioContext = null
		}
	},
	beforeUnmount () {
		clearInterval(this.audioInterval)
		this.turnOffMic()
	}
}
</script>

<style lang="scss" scoped>
@media (min-width: 569px) {
  .batteryAndMicrophoneWidth {
    width:300px !important;
  }
}
@media (max-width: 569px) {
   .batteryAndMicrophoneWidth {
    width:100% !important;
  }
}
</style>