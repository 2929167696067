<template>
  <div
    v-if="job.AudioOnly === 0"
    style="position:absolute;top:0px; left:0px;height:100%;width:100%"
    class="d-flex align-items-center justify-content-center"
  >
    <div
      v-if="stopButtonPressed && !isPractiseQuestion && job.DisablePlayback"
      class="lgfont-resp grey-text"
      style="font-size: 1.5rem;"
    >
      PLAYBACK DISABLED
    </div>
    <mdb-icon
      v-else
      icon="fa fa-pulse fa-spinner lgfont-resp grey-text"
      title="Spinner icon"
      alt="Spinner icon"
      style="font-size: 4rem;transform:translateY(20px)"
    />
  </div>
  <div
    v-else
    style="position:absolute;left:0px;height:100%;width:100%"
    class="d-flex align-items-center justify-content-center"
  >
    <div v-if="job.AllowRetries">
      <div
        v-if="stopButtonPressed && !isPractiseQuestion && job.DisablePlayback"
        class="lgfont-resp grey-text"
        style="font-size: 1.5rem;"
      >
        PLAYBACK DISABLED
      </div>
      <div v-else>
        <mdb-icon
          v-if="!stopButtonPressed"
          icon="fa fa-user lgfont-resp grey-text"
          :class="{ 'silhouetteMargin' : iOS }"
          style="font-size: 8rem;transform:translateY(20px)"
        />
      </div>
    </div>
    <div v-else>
      <div v-if="isPractiseQuestion">
        <mdb-icon
          v-if="!stopButtonPressed"
          icon="fa fa-user lgfont-resp grey-text"
          title="User icon"
          alt="User icon"
          :class="{ 'silhouetteMargin' : iOS }"
          style="font-size: 8rem;transform:translateY(20px)"
        />
      </div>
      <div v-else>
        <div
          v-if="stopButtonPressed && !isPractiseQuestion && job.DisablePlayback"
          class="lgfont-resp grey-text"
          style="font-size: 1.5rem;"
        >
          PLAYBACK DISABLED
        </div>
        <mdb-icon
          v-else
          icon="fa fa-user lgfont-resp grey-text"
          title="User icon"
          alt="User icon"
          :class="{ 'silhouetteMargin' : iOS }"
          style="font-size: 8rem;transform:translateY(20px)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
	name: 'Playback',
	props: {
		stopButtonPressed: {
			type: Boolean
		},
		isPractiseQuestion: {
			type: Boolean
		},
		iOS: {
			type:Boolean
		}
	},
	computed: {
		...mapGetters(["job"])
	}
}
</script>