import Vue from "vue"
import Vuex from "vuex"

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		DateAdded: '',
		ExpiryDate: '',
		IsDeleted: false,
		IsShineGo: true,
		ClosedCode: '',
		tabDesc: 'Welcome',
		Candidate: { },
		Branding: {
			HeaderColour: "#ff7e00",
			PrimaryColour: "#ff7e00",
			SecondaryColour: "#ffffff",
			LinkColour: "#ffffff",
			AltLkColour: "#ffffff",
			AltBgColour: "#ffffff",
			Logo: "https://go.shineinterview.com/img/shine-logo-white.png",
			NavColour: "#ff7e00",
		},
		Job: { },
		InitialUnansweredCount: 0,
		Logging: true,
		Position: 0,
		ProgressStatus: 1,
		UploadProgressPercentageArray: [],
		Questions: [
			// { Description: "Please tell us about your favourite food?", HasAnswered: false, IsPractiseQuestion: true, IsVideo: false, JobId: 6004, Ordinal: 0, OriginalOrdinal: 0, QuestionId: 19973, RetryCount: 0, TimeLimit: 60 },
			// { Description: "Video Question 1", HasAnswered: false, IsPractiseQuestion: false, IsVideo: true, JobId: 6004, Ordinal: 2, OriginalOrdinal: 2, QuestionId: 19975, RetryCount: 1, TimeLimit: 45, VideoUrl: "https://shineuploads.blob.core.windows.net/cornerwaysdigitallimited-uploads/2964db89-9435-4980-9c1f-b9720f79062a.mp4?sv=2014-02-14&sr=b&sig=GauJdVSImh2jl%2Bx6UdNFXBGfSYbodbxJ4hlzI9O9zjM%3D&se=9999-12-31T23%3A59%3A59Z&sp=r"},
			// { Description: "Question 3", HasAnswered: false, IsPractiseQuestion: false, IsVideo: false, JobId: 6004, Ordinal: 3, OriginalOrdinal: 3, QuestionId: 19976, RetryCount: 0, TimeLimit: 45 }
		],
		TemporaryBlobStorage: [],
		ErrorDescription: '',
		OutroVideoUrl: "N/A",
		OutroVideoThumbnailUrl: "N/A"
	},
	mutations: {
		INITIALISE: (state, payload) => {
			Object.assign(state, payload)
			// creates an array with an initial upload percentage of 0 for each question
			if (payload.Questions != undefined) {
				payload.Questions.map(q => {
					const key =  q.QuestionId
					if(q.HasAnswered) {
						state.UploadProgressPercentageArray.push({ QuestionId: key, Percentage: 100, BlockTotal: 1, BlockCount: 1, UploadState: 'PreviouslyAnswered' })
					} else {
						state.UploadProgressPercentageArray.push({ QuestionId: key, Percentage: 0, BlockTotal: 0, BlockCount: 0, UploadState: '' })
					}
				})
				// adds an additional field to determine whether the question has been previously answered and successfully - needs to be different from the 
				//"hasAnswered" field as this is changed locally at the point of going to next question - different from
  
				state.InitialUnansweredCount = payload.Questions.filter(q => q.HasAnswered === true && q.IsPractiseQuestion === false && q.IsTheScenario == false).length
			}
		},
		SET_CANDIDATE: (state, payload) => { state.candidate = payload },
		SET_TABDESC: (state, desc) => state.tabDesc = desc,
		INCREASE_POSITION: (state) => state.Position += 1,
		UPDATE_UPLOAD_PERCENTAGE: (state, { QuestionId, BlockTotal }) => {
			let updatedValue = state.UploadProgressPercentageArray.filter(q => q.QuestionId === QuestionId)[0]
			if (BlockTotal !== null) { updatedValue.BlockTotal = BlockTotal }
			updatedValue.BlockCount += 1
			state.UploadProgressPercentageArray.map(q => q.QuestionId === QuestionId ? { ...updatedValue } : q)

		},
		CHANGE_HAS_ANSWERED: (state, { QuestionId }) => { state.Questions.filter(q => q.QuestionId === QuestionId)[0].HasAnswered = true },
		CHANGE_UPLOAD_STATE: (state, { QuestionId, UploadState }) => { 
			state.UploadProgressPercentageArray.filter(q => q.QuestionId === QuestionId)[0].UploadState = UploadState
			if (UploadState === 'PreparingUpload') {
				// resets block count and total if there is a re-upload
				state.UploadProgressPercentageArray = state.UploadProgressPercentageArray.map(i => i.QuestionId === QuestionId ? { ...i, BlockCount: 0, BlockTotal: 0} : i)
			}
		},
		ADD_REMOVE_TEMP_BLOB: (state, { isAdding, blob, questionId }) => {
			try {
				state.TemporaryBlobStorage = state.TemporaryBlobStorage.filter(b => b.questionId !== questionId)
			} catch (e) { 
				// catch reached if adding question blob for the first time as it wont exist
			}
			if(isAdding) {
				state.TemporaryBlobStorage.push({ questionId, blob })
			}
		},
		SET_ERROR_DESCRIPTION: (state, errorDesc) => { state.ErrorDescription = errorDesc },
		SET_ERROR_PAGE: (state) => { state.ErrorDescription = 'GenericError' }
	},
	getters: {
		branding: (state) => state.Branding,
		tabDesc: (state) => state.tabDesc,
		job: (state) => state.Job,
		position: (state) => state.Position,
		progressStatus: (state) => state.ProgressStatus,
		candidateQuestions: (state) => state.Questions,
		candidate: (state) => state.Candidate,
		isShineGo: (state) => state.IsShineGo,
		invitationCode: (state) => state.ClosedCode,
		uploadPercentageArray: (state) => state.UploadProgressPercentageArray,
		initialUnansweredCount: (state) => state.InitialUnansweredCount,
		blobs: (state) => state.TemporaryBlobStorage,
		errorDescription: (state) => state.ErrorDescription,
		outroVideoUrl: (state) => state.OutroVideoUrl,
		outroVideoThumbnailUrl: (state) => state.OutroVideoThumbnailUrl,
		outroVideoShortDescription: (state) => state.OutroVideoShortDescription
	}
})
