<template>
  <div class="tab">
    <div class="tab-inner mx-auto d-flex flex-column align-items-center autoScroll p-4">
      <div class="mb-4 font-weight-bold text-center" style="font-size:0.9rem;">
        IDENTITY CHECK
      </div>
      <div class="mb-3" style="font-size:0.9rem;">
        You are required to provide a form of photographic identity as part of the application process for this role.
      </div>
      <div style="font-size:0.9rem;">
        On the next screen please capture an image of your identification document (e.g. passport, driving license).
      </div>
    </div>
    <div class="bottom-navigation-container">
      <mdb-btn
        size="sm"
        title="NEXT"
        alt="NEXT"
        class="btnSmall"
        color="primary"
        style="width:160px;outline:none;"
        @click="$store.commit('SET_TABDESC', 'RequestIDCheck-CaptureID')"
      >
        NEXT
      </mdb-btn>
    </div>
  </div>
</template>

<script>

export default {
	name: 'FirstMessage',
	data () {
		return {}
	}
}
</script>

<style lang="scss">

</style>
