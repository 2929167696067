<template>
  <div style="height:100%;">
    <FirstMessage v-if="tabDesc === 'RequestIDCheck-FirstMessage'" class="IDCheckComponents" />
    <CaptureID v-else-if="tabDesc === 'RequestIDCheck-CaptureID'" class="IDCheckComponents" />
    <NoIDMessage v-else-if="tabDesc === 'RequestIDCheck-NoIDMessage'" class="IDCheckComponents" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import FirstMessage from './firstMessage.vue'
import CaptureID from './captureID.vue'
import NoIDMessage from './noIDMessage.vue'

export default {
	name: 'RequestIDCheckContainer',
	components: {
		FirstMessage, CaptureID, NoIDMessage
	},
	props: {
	},
	data () {
		return {}
	},
	methods: {},
	computed: {
		...mapGetters(['job', 'candidate', 'tabDesc'])
	}
}
</script>

<style lang="scss">
.IDCheckComponents {
  position: relative;
  height: 100%;
}
</style>
